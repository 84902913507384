import { UserLanguage } from '@/utils/language.util';
import { Label } from '../label/Label.model';

import { EmployeeAvatar } from '@/domain/employee/Employee.model';

// End of cycles //
// Survey results

export type UserSurvey = {
    survey: Survey;
    remainingQuestions: SurveyQuestion[];
    remainingQuestionCount: number;
    questionCount: number;
};

export type SurveyAnswerMutation = {
    surveyQuestionId: number;
    textAnswer: string;
    anonymous: boolean;
    skipped: boolean;
    answerSurveyQuestionItemIds: number[];
    comment: string;
};

export type SurveyAnswer = {
    id: number;
    surveyId: number;
    surveyQuestionId: number;
    anonymous: boolean;
    skipped: boolean;
    textAnswer?: string;
    items: SurveyQuestionItem[];
    comment: string;
};

export enum SurveyResultFilterType {
    ALL = 'ALL',
    LOCATION = 'LOCATION',
    GENDER = 'GENDER',
    DEPARTMENT = 'DEPARTMENT',
    AGE = 'AGE',
}

export type SurveyCompletion = {
    key: string;
    label: Label;
    answered: number;
    notAnswered: number;
};

export type SurveyParticipation = {
    key: string;
    label: Label;
    invited: number;
    participated: number;
};

export type AnswerItemResult = {
    item: SurveyQuestionItem;
    count: number;
    score: number;
};

export type AnswerCommentResult = {
    comment: string;
    user?: EmployeeAvatar;
};

export type AnswerTextResult = {
    text: string;
    user?: EmployeeAvatar;
};

export type AnswerWordCount = {
    value: string;
    count: number;
};

export type AnswerResultDetails = {
    key: string;
    label: Label;
    wordCounts: AnswerWordCount[];
    textAnswers: AnswerTextResult[];
    comments: AnswerCommentResult[];
    items: AnswerItemResult[];
    averageScore: number;
};

export type SurveyAnswerCompletion = {
    key: string;
    label: Label;
    skipped: number;
    answered: number;
};

export type SurveyCategoryResult = {
    key: string;
    label: Label;
    categoryAverageScore: Record<SurveyQuestionCategory, number>;
};

export type AnswerResult = {
    question: SurveyQuestion;
    completions: Record<SurveyResultFilterType, SurveyAnswerCompletion[]>;
    details: Record<SurveyResultFilterType, AnswerResultDetails[]>;
};

export type SurveyResults = {
    survey: Survey;
    participations: Record<SurveyResultFilterType, SurveyParticipation[]>;
    completions: Record<SurveyResultFilterType, SurveyCompletion[]>;
    categories: Record<SurveyResultFilterType, SurveyCategoryResult[]>;
    answerResults: AnswerResult[];
}; // Surveys

export enum SurveyQuestionType {
    TEXT = 'TEXT',
    SECTION = 'SECTION',
    SINGLE_CHOICE = 'SINGLE_CHOICE',
    MULTI_CHOICE = 'MULTI_CHOICE',
    LICKERT_SCALE = 'LICKERT_SCALE',
    OPINION_SCALE = 'OPINION_SCALE',
    NPS = 'NPS',
}

export type SurveyQuestionItem = {
    id: number;
    label: Label;
    order: number;
    score: number;
    imageUrl: string;
};

export enum SurveyQuestionCategory {
    PERSONAL_GROWTH = 'PERSONAL_GROWTH',
    STRONG_LEADERSHIP = 'STRONG_LEADERSHIP',
    POSITIVE_RELATIONSHIPS = 'POSITIVE_RELATIONSHIPS',
    MISSION_VALUES = 'MISSION_VALUES',
    HEALTH_WELL_BEING = 'HEALTH_WELL_BEING',
    FULFILLING_ROLE = 'FULFILLING_ROLE',
    // this is a fake category, used for questions that don't belong to any of the above
    OTHER = 'OTHER',
}

export const SURVEY_QUESTION_CATEGORIES = [
    SurveyQuestionCategory.FULFILLING_ROLE,
    SurveyQuestionCategory.HEALTH_WELL_BEING,
    SurveyQuestionCategory.MISSION_VALUES,
    SurveyQuestionCategory.PERSONAL_GROWTH,
    SurveyQuestionCategory.POSITIVE_RELATIONSHIPS,
    SurveyQuestionCategory.STRONG_LEADERSHIP,
];

export const getSurveyCategoryTranslationKey = (category: SurveyQuestionCategory | undefined): string => {
    switch (category) {
        case SurveyQuestionCategory.FULFILLING_ROLE:
            return 'survey_category.FULFILLING_ROLE';
        case SurveyQuestionCategory.PERSONAL_GROWTH:
            return 'survey_category.PERSONAL_GROWTH';
        case SurveyQuestionCategory.STRONG_LEADERSHIP:
            return 'survey_category.STRONG_LEADERSHIP';
        case SurveyQuestionCategory.POSITIVE_RELATIONSHIPS:
            return 'survey_category.POSITIVE_RELATIONSHIPS';
        case SurveyQuestionCategory.MISSION_VALUES:
            return 'survey_category.MISSION_VALUES';
        case SurveyQuestionCategory.HEALTH_WELL_BEING:
            return 'survey_category.HEALTH_WELL_BEING';
        default:
            return 'survey_category.OTHER_CATEGORY';
    }
};

export type SurveyQuestion = {
    id: number;
    question: Label;
    type: SurveyQuestionType;
    category?: SurveyQuestionCategory;
    allowComment: boolean;
    comment?: Label;
    order: number;
    items: SurveyQuestionItem[];
};

// End of Surveys
// Cycles //

export enum SurveyStatus {
    PUBLISHED = 'PUBLISHED',
    NOT_PUBLISHED = 'NOT_PUBLISHED',
    CLOSED = 'CLOSED',
}

export type SurveyCreationMutation = {
    surveyTemplateId: number;
    emailSubject: Label;
    emailBody: Label;
    recipientIds: number[];
    startDate?: Date;
    endDate?: Date;
};

export type SurveyUpdateMutation = {
    name?: Label;
    startDate?: Date;
    endDate?: Date;
    recipientIds: number[];
};

export type Survey = {
    id: number;
    surveyTemplateId: number;
    name: Label;
    description: Label;
    emailSubject: Label;
    emailBody: Label;
    status: SurveyStatus;
    startDate?: Date;
    endDate: Date;
    createdBy: EmployeeAvatar;
    createdAt?: Date;
    lastReminderSentAt?: Date;
    recipientsCount: number;
    participationCount: number;
    languages: UserLanguage[];
    questions: SurveyQuestion[];
};
