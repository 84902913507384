import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { EmployeeReviewManagerSummary } from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { showCompletedReviewSummary } from '@/domain/employee-review/EmployeeReview.service';
import { Objective } from '@/domain/objective/Objective.model';
import { FeedbackItemsPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackFormPDF';
import { FeedbackLayoutPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackLayoutPDF';
import { SummaryCompletedPDF } from '@/page/review/employee-review-summary-feedback-form-pdf/SummaryCompletedPDF';
import { SummaryFeedbackItemFormType } from '@/page/review/employee-review-summary-feedback-form/SummaryFeedbackForm.schema';
import { UserLanguage } from '@/utils/language.util';
import { FC } from 'react';

type SummaryFeedbackPDFProps = {
    managerSummary: EmployeeReviewManagerSummary;
    summaryFeedbackItems: SummaryFeedbackItemFormType[];
    objectives: Objective[];
    employeeReview: EmployeeReview;
    userLanguage: UserLanguage;
    actions: EmployeeReviewAction[];
};

export const SummaryFeedbackPDF: FC<SummaryFeedbackPDFProps> = ({
    objectives,
    summaryFeedbackItems,
    managerSummary,
    employeeReview,
    userLanguage,
    actions,
}) => {
    return (
        <FeedbackLayoutPDF feedback={managerSummary} employeeReview={employeeReview}>
            {showCompletedReviewSummary(employeeReview, managerSummary) && <SummaryCompletedPDF managerSummary={managerSummary} userLanguage={userLanguage} />}
            <FeedbackItemsPDF objectives={objectives} feedbackItems={summaryFeedbackItems} actions={actions} />
        </FeedbackLayoutPDF>
    );
};
