import { ConfirmDialog } from '@/components/confirmation-dialog/ConfirmDialog';
import { TemporaryFile } from '@/components/file-picker/FilePicker';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeDocumentCreationMutation, Folder, PreviewData } from '@/domain/document/Document.model';
import { bulkCreateDocuments } from '@/domain/document/Document.service';
import { Employee, EmployeeSearch } from '@/domain/employee/Employee.model';
import { EmploymentStatus } from '@/domain/employment/Employment.model';
import { useGetFoldersByType } from '@/hooks/document/Document.hook';
import { useGetEmployees } from '@/hooks/employee/Employee.hook';
import { DocumentPreviewDialog } from '@/page/document/document-preview-dialog/DocumentPreviewDialog';
import { ContentContainer } from '@/page/layout/ContentContainer';
import { Footer } from '@/page/layout/Footer';
import { MassDocumentImportDialog } from '@/page/setting/import/mass-document-import-dialog/MassDocumentImportDialog';
import { SelectResourceDialog } from '@/page/setting/import/mass-document-import-dialog/SelectResourceDialog';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Button, CircularProgress, Paper, Stack } from '@mui/material';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useGetDocumentTags } from '@/hooks/document-tag/DocumentTag.hook';
import { DocumentTag } from '@/domain/document-tag/DocumentTag.model';
import { ImportDocumentGrid, Row } from '@/page/setting/import/import-document-grid/ImportDocumentGrid';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { containsIgnoreCase, removeAccents } from '@/utils/strings.util';

type ValidRow = StrictOverwrite<
    Row,
    {
        folder: NonNullable<Row['folder']>;
        employee: NonNullable<Row['employee']>;
    }
>;

export const ImportDocumentsPage: FC = () => {
    const { t } = useTranslation();
    const [dialogIsOpen, setDialogIsOpen] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);

    // We have to load all the employees to do the matching with uploaded files
    const employeeSearch: EmployeeSearch = {
        statuses: [EmploymentStatus.EMPLOYED, EmploymentStatus.HIRED, EmploymentStatus.ON_LONG_LEAVE, EmploymentStatus.TERMINATED],
    };
    const { data: allEmployees = [], isLoading: isLoadingEmployees, isError: isErrorEmployees, error: errorEmployees } = useGetEmployees(employeeSearch);
    const {
        data: allEmployeesFolders = [],
        isLoading: isLoadingEmployeesFolder,
        isError: isErrorEmployeesFolders,
        error: errorEmployeesFolders,
    } = useGetFoldersByType('EMPLOYEE');

    const { data: documentTags = [], isLoading: isLoadingDocumentTags } = useGetDocumentTags();

    const [documentsToImport, setDocumentsToImport] = useState<Row[]>([]);

    const [dialogToSetEmployeeIsOpen, setDialogToSetEmployeeIsOpen] = useState<boolean>(false);
    const [dialogToSetFolderIsOpen, setDialogToSetFolderIsOpen] = useState<boolean>(false);
    const [rowsToTag, setRowsToTag] = useState<Row[]>();

    const [previewData, setPreviewData] = useState<PreviewData>();

    const agGridWrapper = useAgGridWrapper<Row>();
    const selectedRows = agGridWrapper?.selectedRows;

    const clearSelection = () => {
        agGridWrapper?.gridRef.current?.api.deselectAll();
    };

    const isRowSelected = (doc: Row) => selectedRows.some(d => d.file.id === doc.file.id);

    const handleDocumentsAdded = ({
        documents,
        checkByDisplayName,
        checkByEmployeeCode,
    }: {
        documents: TemporaryFile[];
        checkByDisplayName: boolean;
        checkByEmployeeCode: boolean;
    }) => {
        const documentWithEmployeeMatching = documents.map<Row>(doc => {
            const predicate = buildEmployeeFilterPredicate(doc.name ?? '', {
                checkDisplayName: checkByDisplayName,
                checkEmployeeCode: checkByEmployeeCode,
            });
            const employee = allEmployees.find(predicate);

            const row: Row = {
                employee: employee,
                folder: undefined,
                file: doc,
                tags: [],
                isSetManually: employee === undefined,
            };
            return row;
        });

        setDocumentsToImport(documents => [...documents, ...documentWithEmployeeMatching]);
    };

    const handleImportDocumentsSubmit = async () => {
        try {
            setLoading(true);
            const isValidMutation = (doc: Row): doc is ValidRow => !!doc.employee && !!doc.folder;
            const convertRowToMutation = ({ folder, employee, tags, file }: ValidRow) => {
                return {
                    files: [file],
                    folderId: folder.id,
                    employeeId: employee.id,
                    tagIds: tags.map(tag => tag.id),
                } satisfies EmployeeDocumentCreationMutation;
            };
            const rows = documentsToImport.filter(isValidMutation).map(convertRowToMutation);

            await bulkCreateDocuments(rows);

            showSnackbar(t('import_page.import_documents_page.employee_documents_created'), 'success');
            setDocumentsToImport([]);
        } catch (error) {
            handleError(error);
        }
        setLoading(false);
    };

    const handleEmployeeSelected = (employee: Employee) => {
        setDocumentsToImport(documents => {
            return documents.map<Row>(doc => {
                if (isRowSelected(doc)) {
                    return {
                        ...doc,
                        employee,
                        isSetManually: true,
                    };
                }
                return doc;
            });
        });
        clearSelection();
        setDialogToSetEmployeeIsOpen(false);
    };

    const handleFolderSelected = (folder: Folder) => {
        setDocumentsToImport(documents => {
            return documents.map<Row>(doc => {
                if (isRowSelected(doc)) {
                    return {
                        ...doc,
                        folder,
                    };
                }
                return doc;
            });
        });
        clearSelection();
        setDialogToSetFolderIsOpen(false);
    };

    const handleDownloadClick = async (previewData: PreviewData) => {
        const file = await fetch(previewData.url);
        const blob = await file.blob();

        // force download to avoid just displaying the file
        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = previewData.document.name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    const hasValidRows = documentsToImport.some(doc => doc.employee && doc.folder);

    const handleRemoveSelected = () => {
        setDocumentsToImport(documents => {
            return documents.filter(doc => !isRowSelected(doc));
        });
        clearSelection();
    };

    const handleSetTagClick = () => {
        setRowsToTag(selectedRows);
    };

    const handleTagsSelected = (documentTags: DocumentTag[]) => {
        setDocumentsToImport(documents => {
            return documents.map<Row>(doc => {
                if (isRowSelected(doc)) {
                    return {
                        ...doc,
                        tags: documentTags,
                    };
                }
                return doc;
            });
        });
        clearSelection();
        setRowsToTag(undefined);
    };

    const validToImportCount = documentsToImport.filter(doc => doc.employee && doc.folder).length;

    const invalidToImportCount = documentsToImport.length - validToImportCount;

    return (
        <>
            <ContentContainer>
                {/* Without employee this page can't work */}
                <StateHandler
                    isLoading={isLoadingEmployees || isLoadingEmployeesFolder}
                    isError={isErrorEmployees || isErrorEmployeesFolders}
                    error={errorEmployees || errorEmployeesFolders}
                >
                    <Stack component={Paper} p={2} flex={1}>
                        <ImportDocumentGrid
                            initRef={agGridWrapper.setGridRef}
                            loading={false}
                            rowData={documentsToImport}
                            onSetEmployeeClick={() => setDialogToSetEmployeeIsOpen(true)}
                            onSetFolderClick={() => setDialogToSetFolderIsOpen(true)}
                            onRemoveSelectedClick={handleRemoveSelected}
                            onSetTagClick={handleSetTagClick}
                            onPreviewClick={setPreviewData}
                        />
                    </Stack>
                </StateHandler>
                {previewData && (
                    <DocumentPreviewDialog
                        onClose={() => setPreviewData(undefined)}
                        previewData={previewData}
                        onDownloadClick={() => handleDownloadClick(previewData)}
                    />
                )}
                {dialogIsOpen && (
                    <MassDocumentImportDialog
                        open={true}
                        onSave={documents => {
                            handleDocumentsAdded(documents);
                            setDialogIsOpen(false);
                        }}
                        onClose={() => setDialogIsOpen(false)}
                    />
                )}
                {dialogToSetEmployeeIsOpen && (
                    <SelectResourceDialog
                        header={t('import_page.import_documents_page.select_employee')}
                        open={dialogToSetEmployeeIsOpen}
                        options={allEmployees.map(employee => ({
                            id: employee.id,
                            label: employee.displayName,
                            value: employee,
                        }))}
                        onSelectValue={handleEmployeeSelected}
                        onClose={() => setDialogToSetEmployeeIsOpen(false)}
                        label={t('import_page.import_documents_page.set_employee_label')}
                    />
                )}
                {dialogToSetFolderIsOpen && (
                    <SelectResourceDialog
                        header={t('import_page.import_documents_page.select_folder')}
                        open={dialogToSetFolderIsOpen}
                        options={allEmployeesFolders.map(folder => ({
                            id: folder.id,
                            label: getLabelTranslation(folder.name),
                            value: folder,
                        }))}
                        onSelectValue={handleFolderSelected}
                        onClose={() => setDialogToSetFolderIsOpen(false)}
                        label={t('import_page.import_documents_page.set_folder_label')}
                    />
                )}
                {rowsToTag && (
                    <SelectResourceDialog
                        header={t('import_page.import_documents_page.select_tags')}
                        open={true}
                        options={documentTags.map(documentTag => ({
                            id: documentTag.id,
                            label: getLabelTranslation(documentTag.name),
                            value: documentTag,
                        }))}
                        onSelectValue={handleTagsSelected}
                        multiple={true}
                        onClose={() => setRowsToTag(undefined)}
                        label={t('import_page.import_documents_page.set_tag_label')}
                        isLoadingOptions={isLoadingDocumentTags}
                    />
                )}
            </ContentContainer>
            <Footer>
                <Button variant='outlined' onClick={() => setDialogIsOpen(true)}>
                    {t('import_page.import_documents_page.upload_again')}
                </Button>

                <ConfirmDialog
                    title={t('import_page.import_documents_page.confirm_import')}
                    content={
                        t('import_page.import_documents_page.confirm_import_content', {
                            count: validToImportCount,
                        }) +
                        ' ' +
                        t('import_page.import_documents_page.confirm_import_content_invalid', { count: invalidToImportCount })
                    }
                    onConfirm={handleImportDocumentsSubmit}
                >
                    <Button disabled={!hasValidRows || loading}>
                        {loading ? <CircularProgress size={20} /> : t('import_page.import_documents_page.confirm_import')}
                    </Button>
                </ConfirmDialog>
            </Footer>
        </>
    );
};

const buildEmployeeFilterPredicate =
    (
        name: string,
        {
            checkEmployeeCode,
            checkDisplayName,
        }: {
            checkEmployeeCode: boolean;
            checkDisplayName: boolean;
        } = {
            checkDisplayName: true,
            checkEmployeeCode: true,
        },
    ) =>
    ({ displayName, employeeCode }: Pick<Employee, 'displayName' | 'employeeCode'>): boolean => {
        return (
            (!checkDisplayName || displayName.split(' ').some(part => containsIgnoreCase(removeAccents(name), removeAccents(part)))) &&
            (!checkEmployeeCode || containsIgnoreCase(employeeCode, name))
        );
    };
