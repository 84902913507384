import { SectionLoading } from '@/components/section/SectionLoading';
import { EditableStackSection } from '@/components/section/StackSectionComponent/EditableStackSection';
import { StackSection } from '@/components/section/StackSectionComponent/StackSection';
import { Employee } from '@/domain/employee/Employee.model';
import { mapFieldFormValuesToBasicInfoMutation, updateEmployeeBasicInfo } from '@/domain/employee/Employee.service';
import { canManageEmployees } from '@/domain/permission/Permission.service';
import { SectionDefinition } from '@/domain/section-setting/Section.model';
import { useEmployeeBasicInfoSectionFields } from '@/page/employee-profile/employee-profile-info/EmployeeBasicInfoSection/EmployeeBasicInfoSection.hook';
import {
    getSectionDefinitionSchema,
    SectionDefinitionFormValues,
} from '@/page/employee-profile/employee-profile-info/EmployeeCustomSectionRowDialog/EmployeeSectionDefinition.schema';
import { useCurrentPolicies } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

type Props = {
    employee: Employee;
    onUpdateEmployeeBasicInfo: () => void;
    sectionDefinition: SectionDefinition;
};

export const EmployeeBasicInfoSection: FC<Props> = ({ employee, sectionDefinition, onUpdateEmployeeBasicInfo }) => {
    const { t } = useTranslation();
    const [editable, setEditable] = useState(false);
    const policies = useCurrentPolicies();

    const basicInfoSectionSchema = getSectionDefinitionSchema({ sectionDefinition });

    const fields = useEmployeeBasicInfoSectionFields(sectionDefinition, employee.id, editable);

    const actionButtonEdit = {
        title: t('general.edit'),
        onClick: () => {
            setEditable(true);
        },
    };

    const onSave = async (formValues: SectionDefinitionFormValues) => {
        const employeePersonalInfoUpdateRequest = mapFieldFormValuesToBasicInfoMutation(formValues, sectionDefinition);

        try {
            await updateEmployeeBasicInfo(employee.id, employeePersonalInfoUpdateRequest);
            onUpdateEmployeeBasicInfo();
            setEditable(false);
        } catch (e) {
            handleError(e);
        }
    };

    if (!employee) {
        return <SectionLoading sectionTitle={getLabelTranslation(sectionDefinition.name)} />;
    }

    const getActionButtons = () => {
        if (canManageEmployees(policies, employee.id)) {
            return [actionButtonEdit];
        }
        return [];
    };

    return editable ? (
        <EditableStackSection
            sectionTitle={getLabelTranslation(sectionDefinition.name)}
            fields={fields}
            onSave={onSave}
            schema={basicInfoSectionSchema}
            onCancel={() => setEditable(false)}
        />
    ) : (
        <StackSection sectionTitle={getLabelTranslation(sectionDefinition.name)} fields={fields} actionButtons={getActionButtons()} />
    );
};
