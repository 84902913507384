import { getLabelTranslation } from '@/utils/language.util';
import { Realm, RealmFeaturesType } from '@/domain/realm/Realm.model';
import { Folder } from '@/domain/document/Document.model';
import {
    COMPANY_PERMISSION_POLICY_GROUPS,
    EMPLOYEE_PERMISSION_POLICY_GROUPS,
    PermissionPolicyGroup,
    PermissionPolicySubGroup,
    Policy,
    PolicyGroup,
    PolicyPermission,
    PolicySubGroup,
    ResourceBasedName,
} from '@/domain/permission/Permission.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { SectionDefinition, SectionType } from '@/domain/section-setting/Section.model';
import { useAppSelector } from '@/stores/store';
import { Checkbox, FormControlLabel, ListItem, Stack, Typography, useTheme } from '@mui/material';
import List from '@mui/material/List';
import Tab, { TabProps } from '@mui/material/Tab';
import Tabs, { TabsProps } from '@mui/material/Tabs';
import { FC, Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SquareUnlock02Icon } from 'hugeicons-react';
import { PermissionGroupPolicy, PermissionGroupType } from '@/domain/permission-group/PermissionGroup.model';
import { isCustomSectionType } from '@/domain/section-setting/Section.service';

export type SelectedPolicy = { policyName: PolicyPermission; checked: boolean; resourceId?: number };

type PermissionsPanelProps = {
    permissionSettingsTab: 'EMPLOYEE' | 'COMPANY';
    groupTypes: PermissionGroupType;
    groupPolicies: PermissionGroupPolicy[];
    onChangePolicy: (selectedPolicy: SelectedPolicy) => void;
    sectionDefinitions: SectionDefinition[];
    folders: Folder[];
};

export const PermissionPoliciesPanel: FC<PermissionsPanelProps> = ({
    permissionSettingsTab,
    groupTypes,
    groupPolicies,
    onChangePolicy,
    sectionDefinitions,
    folders,
}) => {
    const { palette } = useTheme();
    const [permissionGroupSelectedTab, setPermissionGroupSelectedTab] = useState<number>(0);

    const realm = useAppSelector(state => state.ui.currentRealm);

    if (!realm) {
        throw new Error('Component must be used in connected context');
    }

    const handlePolicyChange = (selectedPolicy: SelectedPolicy) => {
        const policiesToUpdate: SelectedPolicy[] = [];

        // Expected behavior: When the selected policy is checked, associated policies should also be checked.
        // However, when the selected policy is unchecked, associated policies should remain unchanged.
        if (selectedPolicy?.checked) {
            const associatedPolicies = getAssociatedPolicies(selectedPolicy);
            policiesToUpdate.push(selectedPolicy, ...associatedPolicies);
        } else {
            policiesToUpdate.push(selectedPolicy);
        }

        policiesToUpdate.forEach(policyToUpdate => {
            onChangePolicy(policyToUpdate);
        });
    };

    const filterGroupsByRealm = (groups: PolicyGroup[], realm: Realm) => {
        return groups.filter(filterGroupPermissionsByRealmFeatureEnabled(realm)).map(mapPolicyGroupsToFilterSubGroup(realm));
    };

    const employeePolicyGroups = filterGroupsByRealm(EMPLOYEE_PERMISSION_POLICY_GROUPS, realm);
    const companyPolicyGroups = filterGroupsByRealm(COMPANY_PERMISSION_POLICY_GROUPS, realm);

    // This should be handle with the Tabs theme
    const tabsSxProps: TabsProps['sx'] = {
        marginTop: 0.5,
        minWidth: 212,
        '& button:focus': {
            backgroundColor: palette.grey[100],
            color: palette.text.primary,
        },
        '& button:selected': {
            backgroundColor: palette.grey[100],
            color: palette.text.primary,
        },
    };

    const groups = permissionSettingsTab === 'EMPLOYEE' ? employeePolicyGroups : companyPolicyGroups;

    const focusedGroup = groups[permissionGroupSelectedTab];

    const getFilters = (policySubGroup: PolicySubGroup) => {
        if (
            policySubGroup.resourceBasedName !== ResourceBasedName.COMPANY_DOCUMENTS_SUBGROUP &&
            policySubGroup.resourceBasedName !== ResourceBasedName.EMPLOYEE_DOCUMENTS_SUBGROUP
        ) {
            return [];
        }
        return folders.filter(folder => folder.folderType === permissionSettingsTab);
    };

    const getSectionDefinitions = (group: PolicyGroup, policySubGroup: PolicySubGroup) => {
        const customSectionsTypes: SectionType[] = [SectionType.CUSTOM_MULTI_ROW, SectionType.CUSTOM_SINGLE_ROW];

        return permissionSettingsTab === 'EMPLOYEE' && group.name === PermissionPolicyGroup.EMPLOYEE_PROFILE
            ? sectionDefinitions.filter(
                  section =>
                      (customSectionsTypes.includes(section.type) && ResourceBasedName.EMPLOYEE_SECTION === policySubGroup.resourceBasedName) ||
                      (section.type === SectionType.PERSONAL_INFO && ResourceBasedName.EMPLOYEE_PERSONAL_INFOS === policySubGroup.resourceBasedName) ||
                      (section.type === SectionType.ADDRESS && ResourceBasedName.EMPLOYEE_ADDRESSES === policySubGroup.resourceBasedName),
              )
            : [];
    };

    const defaultSections = sectionDefinitions.filter(section => !isCustomSectionType(section.type));

    return (
        <Stack direction='row' gap={2}>
            <Tabs
                scrollButtons={false}
                sx={tabsSxProps}
                TabIndicatorProps={{
                    hidden: true,
                }}
                orientation='vertical'
                variant='scrollable'
                value={permissionGroupSelectedTab}
                onChange={(_event, value) => setPermissionGroupSelectedTab(value)}
            >
                {groups.map(({ name }, index) => (
                    <GroupTab key={name} name={name} {...a11yProps(index)} />
                ))}
            </Tabs>

            <List disablePadding>
                {focusedGroup?.policySubGroup.map(policySubGroup => (
                    <SubGroupItem
                        key={policySubGroup.subGroupName + '-' + policySubGroup.resourceBasedName}
                        policySubGroup={policySubGroup}
                        groupPolicies={groupPolicies}
                        folders={getFilters(policySubGroup)}
                        defaultSectionDefinitions={defaultSections}
                        sectionDefinitions={getSectionDefinitions(focusedGroup, policySubGroup)}
                        onChangePolicy={handlePolicyChange}
                        groupTypes={groupTypes}
                    />
                ))}
            </List>
        </Stack>
    );
};

type AssociatedPermissionsConfigKeys =
    | PolicyPermission.VIEW_EMPLOYEES
    | PolicyPermission.VIEW_EMPLOYEE_PHONE_NUMBERS
    | PolicyPermission.VIEW_EMPLOYEE_EMAILS
    | PolicyPermission.VIEW_EMPLOYEE_BASIC_INFOS
    | PolicyPermission.SEE_WHO_IS_ON_LEAVE
    | PolicyPermission.VIEW_EMPLOYEE_SECTIONS
    | PolicyPermission.VIEW_EMPLOYEE_LEAVE_TYPES
    | PolicyPermission.VIEW_TIMESHEETS
    | PolicyPermission.VIEW_EMPLOYEE_PERSONAL_INFOS
    | PolicyPermission.MANAGE_PENDING_EMPLOYEE_PERSONAL_INFOS
    | PolicyPermission.MANAGE_PENDING_EMPLOYEE_SECTIONS
    | PolicyPermission.VIEW_EMPLOYEE_CONTRACTS
    | PolicyPermission.VIEW_OBJECTIVES
    | PolicyPermission.VIEW_DOCUMENTS_IN_COMPANY_FOLDER
    | PolicyPermission.MANAGE_DOCUMENTS_IN_COMPANY_FOLDER
    | PolicyPermission.VIEW_DOCUMENTS_IN_EMPLOYEE_FOLDER
    | PolicyPermission.MANAGE_DOCUMENTS_IN_EMPLOYEE_FOLDER
    | PolicyPermission.VIEW_SHIFTS
    | PolicyPermission.MANAGE_PENDING_EMPLOYEE_ADDRESSES
    | PolicyPermission.VIEW_EMPLOYEE_ADDRESSES
    | PolicyPermission.LOCK_EMPLOYEE_PAYROLL_LOCKS;

/**
 * This configuration allows you to manage the behavior of checkboxes in relation to each other.
 */

const ASSOCIATED_PERMISSIONS_CONFIG: Record<AssociatedPermissionsConfigKeys, PolicyPermission[]> = {
    [PolicyPermission.VIEW_EMPLOYEES]: [
        PolicyPermission.MANAGE_EMPLOYEE_BASIC_INFOS,
        PolicyPermission.VIEW_EMPLOYEE_BASIC_INFOS,
        PolicyPermission.VIEW_EMPLOYEE_PHONE_NUMBERS,
        PolicyPermission.VIEW_EMPLOYEE_EMAILS,
    ],
    [PolicyPermission.VIEW_EMPLOYEE_PHONE_NUMBERS]: [PolicyPermission.VIEW_EMPLOYEE_BASIC_INFOS, PolicyPermission.MANAGE_EMPLOYEE_BASIC_INFOS],
    [PolicyPermission.VIEW_EMPLOYEE_EMAILS]: [PolicyPermission.VIEW_EMPLOYEE_BASIC_INFOS, PolicyPermission.MANAGE_EMPLOYEE_BASIC_INFOS],
    [PolicyPermission.VIEW_EMPLOYEE_BASIC_INFOS]: [PolicyPermission.MANAGE_EMPLOYEE_BASIC_INFOS],
    [PolicyPermission.SEE_WHO_IS_ON_LEAVE]: [
        PolicyPermission.MANAGE_APPROVED_LEAVE_REQUEST,
        PolicyPermission.MANAGE_PENDING_LEAVE_REQUEST,
        PolicyPermission.APPROVE_REJECT_LEAVE_REQUEST,
        PolicyPermission.VIEW_EMPLOYEE_LEAVE_TYPES,
    ],
    [PolicyPermission.VIEW_EMPLOYEE_SECTIONS]: [PolicyPermission.MANAGE_PENDING_EMPLOYEE_SECTIONS, PolicyPermission.MANAGE_EMPLOYEE_SECTIONS],
    [PolicyPermission.MANAGE_PENDING_EMPLOYEE_SECTIONS]: [PolicyPermission.MANAGE_EMPLOYEE_SECTIONS],
    [PolicyPermission.VIEW_EMPLOYEE_LEAVE_TYPES]: [
        PolicyPermission.MANAGE_APPROVED_LEAVE_REQUEST,
        PolicyPermission.MANAGE_PENDING_LEAVE_REQUEST,
        PolicyPermission.APPROVE_REJECT_LEAVE_REQUEST,
    ],
    [PolicyPermission.VIEW_TIMESHEETS]: [
        PolicyPermission.MANAGE_PENDING_TIMESHEETS,
        PolicyPermission.APPROVE_REJECT_TIMESHEETS,
        PolicyPermission.MANAGE_APPROVED_TIMESHEETS,
    ],
    [PolicyPermission.VIEW_EMPLOYEE_PERSONAL_INFOS]: [PolicyPermission.MANAGE_PENDING_EMPLOYEE_PERSONAL_INFOS, PolicyPermission.MANAGE_EMPLOYEE_PERSONAL_INFOS],
    [PolicyPermission.MANAGE_PENDING_EMPLOYEE_PERSONAL_INFOS]: [PolicyPermission.MANAGE_EMPLOYEE_PERSONAL_INFOS],
    [PolicyPermission.VIEW_EMPLOYEE_ADDRESSES]: [PolicyPermission.MANAGE_PENDING_EMPLOYEE_ADDRESSES, PolicyPermission.MANAGE_EMPLOYEE_ADDRESSES],
    [PolicyPermission.MANAGE_PENDING_EMPLOYEE_ADDRESSES]: [PolicyPermission.MANAGE_EMPLOYEE_ADDRESSES],
    [PolicyPermission.VIEW_EMPLOYEE_CONTRACTS]: [PolicyPermission.MANAGE_EMPLOYEE_CONTRACTS],
    [PolicyPermission.VIEW_OBJECTIVES]: [PolicyPermission.EDIT_OBJECTIVES, PolicyPermission.CREATE_OBJECTIVES, PolicyPermission.UPDATE_OBJECTIVE_STATUS],
    [PolicyPermission.VIEW_DOCUMENTS_IN_COMPANY_FOLDER]: [
        PolicyPermission.MANAGE_DOCUMENTS_IN_COMPANY_FOLDER,
        PolicyPermission.DELETE_DOCUMENTS_IN_COMPANY_FOLDER,
    ],
    [PolicyPermission.MANAGE_DOCUMENTS_IN_COMPANY_FOLDER]: [PolicyPermission.DELETE_DOCUMENTS_IN_COMPANY_FOLDER],
    [PolicyPermission.VIEW_DOCUMENTS_IN_EMPLOYEE_FOLDER]: [
        PolicyPermission.MANAGE_DOCUMENTS_IN_EMPLOYEE_FOLDER,
        PolicyPermission.DELETE_DOCUMENTS_IN_EMPLOYEE_FOLDER,
    ],
    [PolicyPermission.MANAGE_DOCUMENTS_IN_EMPLOYEE_FOLDER]: [PolicyPermission.DELETE_DOCUMENTS_IN_EMPLOYEE_FOLDER],
    [PolicyPermission.VIEW_SHIFTS]: [PolicyPermission.MANAGE_SHIFTS],
    [PolicyPermission.LOCK_EMPLOYEE_PAYROLL_LOCKS]: [PolicyPermission.MANAGE_EMPLOYEE_PAYROLL_LOCKS],
};

const SubGroupItem: FC<{
    policySubGroup: PolicySubGroup;
    folders: Folder[];
    defaultSectionDefinitions: SectionDefinition[];
    sectionDefinitions: SectionDefinition[];
    groupPolicies: PermissionGroupPolicy[];
    groupTypes: PermissionGroupType;
    onChangePolicy: (selectedPolicy: SelectedPolicy) => void;
}> = ({ policySubGroup, folders, defaultSectionDefinitions, sectionDefinitions, onChangePolicy, groupPolicies, groupTypes }) => {
    const { t } = useTranslation();

    const getTitle = (policySubGroup: PolicySubGroup) => {
        // case for default section, name should be from default sectionDefinitions
        const defaultSectionTitle = getDefaultSectionTitle(policySubGroup, defaultSectionDefinitions);

        // Workaround to handle mess from translations files
        const titleFromSubGroupTranslation = t('permissions_setting_page.subgroups', {
            context: policySubGroup.subGroupName,
            defaultValue: '',
        });
        const titleFromGroupTranslation = t('permissions_setting_page.groups', {
            context: policySubGroup.subGroupName,
            defaultValue: '',
        });
        return defaultSectionTitle || titleFromSubGroupTranslation || titleFromGroupTranslation || policySubGroup.subGroupName;
    };

    const renderPolicyListItem = (id: number, policy: Policy) => (
        <PolicyListItem
            key={id + policy.name}
            policy={policy}
            checked={isResourceBasedPolicy(groupPolicies)(policy, id)}
            disabled={isPolicyProtectedOnDefaultGroup(groupTypes)(policy) || hasResourceBasedAssociatedPermissions(groupPolicies)(policy, id)}
            onChangePolicy={onChangePolicy}
            resourceId={id}
        />
    );

    return (
        <>
            {!policySubGroup?.resourceBasedName && (
                <>
                    <SubGroupTitle name={getTitle(policySubGroup)} />
                    {policySubGroup?.policies.map(policy => (
                        <PolicyListItem
                            key={policy.name}
                            policy={policy}
                            checked={isPolicyChecked(groupPolicies)(policy.name)}
                            disabled={isPolicyProtectedOnDefaultGroup(groupTypes)(policy) || hasAssociatedCheckboxesChecked(groupPolicies)(policy.name)}
                            onChangePolicy={onChangePolicy}
                        />
                    ))}
                </>
            )}

            {sectionDefinitions?.map(section => (
                <Fragment key={section.id + '-' + section.name.id}>
                    <SubGroupTitle name={getLabelTranslation(section.name)} />
                    {policySubGroup.policies.map(policy => renderPolicyListItem(section.id, policy))}
                </Fragment>
            ))}

            {folders?.map(folder => (
                <Fragment key={folder.id + '-' + getLabelTranslation(folder.name)}>
                    <SubGroupTitle name={getLabelTranslation(folder.name)} />
                    {policySubGroup.policies.map(policy => renderPolicyListItem(folder.id, policy))}
                </Fragment>
            ))}
        </>
    );
};

const filterGroupPermissionsByRealmFeatureEnabled = (realm: Realm) => (group: PolicyGroup) => {
    switch (group.name) {
        case PermissionPolicyGroup.COMPANY_DOCUMENTS:
        case PermissionPolicyGroup.EMPLOYEE_DOCUMENTS:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.DOCUMENTS);
        case PermissionPolicyGroup.LEAVE:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.LEAVES);
        case PermissionPolicyGroup.TIMESHEETS:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.TIMESHEET);
        case PermissionPolicyGroup.REVIEWS:
        case PermissionPolicyGroup.OBJECTIVES:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.REVIEWS);
        case PermissionPolicyGroup.SHIFTS:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.PLANNING);
        case PermissionPolicyGroup.PAYROLL:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.PAYROLL);
        case PermissionPolicyGroup.SURVEYS:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.SURVEYS);
        default:
            return true;
    }
};

const filterSubGroupPermissionsByRealmFeatureEnabled = (realm: Realm) => (policy: Policy) => {
    switch (policy.name) {
        case PolicyPermission.CONFIGURE_DOCUMENTS:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.DOCUMENTS);
        case PolicyPermission.CONFIGURE_OBJECTIVES:
        case PolicyPermission.CONFIGURE_REVIEWS:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.REVIEWS);
        case PolicyPermission.CONFIGURE_TIME_MANAGEMENT:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.TIMESHEET);
        case PolicyPermission.CONFIGURE_PLANNINGS:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.PLANNING);
        case PolicyPermission.CONFIGURE_EMPLOYEE_PAYROLL:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.PAYROLL);
        case PolicyPermission.CONFIGURE_LEAVES:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.LEAVES);
        case PolicyPermission.CLOCK_IN_TIMESHEETS:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.CLOCK_IN_OUT);
        case PolicyPermission.CONFIGURE_THIRD_PARTIES:
            return hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.THIRD_PARTIES);
        default:
            return true;
    }
};

const mapPolicyGroupsToFilterSubGroup =
    (realm: Realm) =>
    (group: PolicyGroup): PolicyGroup => {
        return {
            ...group,
            policySubGroup: group.policySubGroup.map(subGroup => ({
                ...subGroup,
                policies: subGroup.policies?.filter(filterSubGroupPermissionsByRealmFeatureEnabled(realm)),
            })),
        };
    };

const SubGroupTitle = ({ name }: { name: string }) => {
    return (
        <Typography variant='body1bold' py={1.5}>
            {name}
        </Typography>
    );
};

const isPolicyProtectedOnDefaultGroup = (groupTypes: PermissionGroupType) => (policy: Policy) => {
    return !policy.editableBy.some(groupType => groupType === groupTypes);
};

const isPolicyChecked = (groupPolicies: PermissionGroupPolicy[]) => (policyPermission: PolicyPermission) => {
    return groupPolicies.findIndex(groupPolicy => groupPolicy.policy === policyPermission) >= 0;
};

const isPermissionInConfigKeys = (value: PolicyPermission): value is AssociatedPermissionsConfigKeys => {
    return Object.values(PolicyPermission).includes(value);
};

const hasResourceBasedPolicyCheckboxesChecked = (groupPolicies: PermissionGroupPolicy[]) => (associatedPermission: PolicyPermission, resourceId: number) =>
    groupPolicies.findIndex(groupPolicy => groupPolicy.policy === associatedPermission && groupPolicy.resourceId === resourceId) >= 0;

const hasResourceBasedAssociatedPermissions =
    (groupPolicies: PermissionGroupPolicy[]) =>
    (policy: Policy, resourceId: number): boolean => {
        if (isPermissionInConfigKeys(policy.name)) {
            const associatedPermissions = ASSOCIATED_PERMISSIONS_CONFIG[policy.name];
            return associatedPermissions?.some(associatedPermission => {
                return hasResourceBasedPolicyCheckboxesChecked(groupPolicies)(associatedPermission, resourceId);
            });
        }
        return false;
    };

const hasAssociatedCheckboxesChecked = (groupPolicies: PermissionGroupPolicy[]) => (policyPermission: PolicyPermission) => {
    if (isPermissionInConfigKeys(policyPermission)) {
        const associatedPermissions = ASSOCIATED_PERMISSIONS_CONFIG[policyPermission];
        return associatedPermissions?.some(associatedPermission => isPolicyChecked(groupPolicies)(associatedPermission)) ?? false;
    }
    return false;
};

const isResourceBasedPolicy = (groupPolicies: PermissionGroupPolicy[]) => (policy: Policy, resourceId: number) => {
    const isResourceBasedPolicyChecked = hasResourceBasedPolicyCheckboxesChecked(groupPolicies)(policy.name, resourceId);
    // we don't want this function to return undefined because it will cause mui issues on the PolicyListItem component
    // "A component is changing an uncontrolled input to be controlled. " error
    // so we return false if the value is undefined
    return isResourceBasedPolicyChecked ?? false;
};

type PolicyListItemProps = {
    policy: Policy;
    checked: boolean;
    disabled: boolean;
    resourceId?: number;
    onChangePolicy: (selectedPolicy: SelectedPolicy) => void;
};

const PolicyListItem: FC<PolicyListItemProps> = ({ policy, disabled, checked, resourceId, onChangePolicy }) => {
    const { t } = useTranslation();
    const labelId = `checkbox-list-secondary-label-${policy.name}`;

    return (
        <ListItem key={policy.name}>
            <FormControlLabel
                label={t('domain.policy.enum', { context: policy.name })}
                labelPlacement={'end'}
                sx={{ height: 24 }}
                control={
                    <Checkbox
                        sx={theme => ({
                            borderRadius: theme.shape.borderRadius,
                        })}
                        disabled={disabled}
                        edge='start'
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            onChangePolicy({
                                policyName: policy.name,
                                checked: event.target.checked,
                                resourceId,
                            });
                        }}
                        checked={checked}
                        inputProps={{ 'aria-labelledby': labelId }}
                    />
                }
            />
        </ListItem>
    );
};

const GroupTab: FC<{ name: string } & TabProps> = ({ name, ...rest }) => {
    const { t } = useTranslation();
    const { palette } = useTheme();
    return (
        <Tab
            icon={<SquareUnlock02Icon />}
            iconPosition={'start'}
            sx={{
                '&.Mui-selected': { backgroundColor: palette.grey[100], color: palette.text.primary },
                color: palette.text.disabled,
                borderRadius: 1,
                height: 28,
                minHeight: 28,
                width: '100%',
                justifyContent: 'flex-start',
                typography: 'body1',
                marginY: 0.5,
            }}
            label={t('permissions_setting_page.groups', {
                context: name,
            })}
            {...rest}
        />
    );
};

function a11yProps(index: number) {
    return {
        id: `vertical-tab-${index}`,
        'aria-controls': `vertical-tabpanel-${index}`,
    };
}

const findPolicyPermissionsForValue = (policyPermission: PolicyPermission): PolicyPermission[] => {
    return Object.keys(ASSOCIATED_PERMISSIONS_CONFIG)
        .filter(key => {
            const associatedPermissions = ASSOCIATED_PERMISSIONS_CONFIG[key as AssociatedPermissionsConfigKeys];
            return associatedPermissions.includes(policyPermission);
        })
        .map(key => key as PolicyPermission);
};

const getAssociatedPolicies = (selectedPolicy: SelectedPolicy): SelectedPolicy[] => {
    return (
        findPolicyPermissionsForValue(selectedPolicy.policyName)?.map(associatedPolicyName => ({
            policyName: associatedPolicyName,
            checked: selectedPolicy.checked,
            resourceId: selectedPolicy.resourceId,
        })) || []
    );
};

const getDefaultSectionTitle = (policySubGroup: PolicySubGroup, sectionDefinitions: SectionDefinition[]) => {
    if (policySubGroup.subGroupName === PermissionPolicySubGroup.BASIC_INFO) {
        return getLabelTranslation(sectionDefinitions.find(section => section.type === SectionType.BASIC_INFO)?.name);
    }
    switch (policySubGroup.resourceBasedName) {
        case ResourceBasedName.EMPLOYEE_PERSONAL_INFOS:
            return getLabelTranslation(sectionDefinitions.find(section => section.type === SectionType.PERSONAL_INFO)?.name);
        case ResourceBasedName.EMPLOYEE_ADDRESSES:
            return getLabelTranslation(sectionDefinitions.find(section => section.type === SectionType.ADDRESS)?.name);
        default:
            return '';
    }
};
