import { authApi } from '@/api/auth/AuthApi';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { EmployeeNotification, EmployeeNotificationUpdateMutation } from '@/domain/employee-notification/EmployeeNotification.model';
import { updateOwnEmployeeNotifications } from '@/domain/employee-notification/EmployeeNotification.service';
import { EmployeeLanguageUpdateRequest } from '@/domain/employee/Employee.model';
import { updateEmployeeLanguage } from '@/domain/employee/Employee.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { useGetOwnEmployeeNotification } from '@/hooks/employee-notification/EmployeeNotification.hook';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { useLocalStorage } from '@/hooks/Storage.hook';
import { employeeLanguageUpdated } from '@/stores/reducers/currentEmployeeSlice';
import { useAppDispatch, useAppSelector, useCurrentRealm } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getTodayDate } from '@/utils/datetime.util';
import { setUserLanguage, UserLanguage } from '@/utils/language.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { Button, Checkbox, FormControl, FormControlLabel, Paper, Radio, RadioGroup, Stack, Typography } from '@mui/material';
import { ChangeEvent, FC, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const EmployeeSettingsPage: FC = () => {
    const { data: employeeNotification, isLoading, isError, error } = useGetOwnEmployeeNotification();

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error} isEmpty={!employeeNotification}>
            {employeeNotification && <EmployeeSettingsForm empNotification={employeeNotification} />}
        </StateHandler>
    );
};

type EmployeeSettingsFormProps = {
    empNotification: EmployeeNotification;
};

const EmployeeSettingsForm: FC<EmployeeSettingsFormProps> = ({ empNotification }) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [employeeNotification, setEmployeeNotification] = useState<EmployeeNotification>(empNotification);
    const { t } = useTranslation();

    const currentEmployeeState = useAppSelector(state => state.currentEmployee);

    const currentLanguage = currentEmployeeState.language;
    const currentEmployeeEmail = currentEmployeeState.email;
    const currentEmployeeId = currentEmployeeState.employee?.id;

    if (!currentLanguage || !currentEmployeeEmail || !currentEmployeeId) {
        throw new Error('Employee not authenticated');
    }

    const realm = useCurrentRealm();
    const dispatch = useAppDispatch();

    const [lastResetTime, setLastResetTime] = useLocalStorage<string>('RESET_PASSWORD_TIME', '');

    const hasLeaveFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.LEAVES);
    const isPlanningFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.PLANNING);
    const isDocumentFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.DOCUMENTS);
    const isAdvancedProfileFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.ADVANCED_EMPLOYEE_PROFILE);
    const isTimesheetFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.TIMESHEET);

    const resetPasswordDisabled: boolean = lastResetTime != '' && getTodayDate().getTime() - parseInt(lastResetTime) < 60 * 1000;

    const resetPassword = () => {
        setLoading(true);

        authApi
            .resetPassword(realm.tenantId, currentEmployeeEmail)
            .then(() => {
                setLastResetTime(getTodayDate().getTime().toFixed());
            })
            .catch(error => {
                handleError(error);
            })
            .finally(() => {
                showSnackbar(t('user_settings.messages.password_reset_email_sent'), 'success');
                setLoading(false);
            });
    };

    const handleLanguageChange = async (event: ChangeEvent<HTMLInputElement>) => {
        const newLanguage: EmployeeLanguageUpdateRequest = {
            language: event.target.value as UserLanguage,
        };
        if (newLanguage.language !== currentLanguage) {
            setUserLanguage(newLanguage.language).catch(handleError);
        }
        try {
            await updateEmployeeLanguage(newLanguage);
            dispatch(employeeLanguageUpdated(newLanguage.language));
        } catch (error) {
            handleError(error);
        }
    };

    const handleNotificationChange = async (setting: Partial<EmployeeNotificationUpdateMutation>) => {
        const notifications: EmployeeNotificationUpdateMutation = {
            employeeId: currentEmployeeId,
            ...employeeNotification,
            ...setting,
        };

        try {
            await updateOwnEmployeeNotifications(notifications);
            setEmployeeNotification({ ...employeeNotification, ...notifications });
        } catch (error) {
            handleError(error);
        }
    };

    const isStandardLogin = realm?.availableLoginMethods?.length === 1 && realm.availableLoginMethods?.[0].type === 'STANDARD';

    return (
        <Stack direction='column' flexWrap={'nowrap'} gap={2} bgcolor={({ palette }) => palette.background.default} flex='1'>
            <Paper sx={{ p: 2 }}>
                <Typography variant='body2bold'>{t('user_settings.user_info')}</Typography>
                <Stack direction='column' gap={2} pt={2}>
                    <Stack>
                        <Typography variant='body2'>{t('user_settings.username')}</Typography>
                        <Typography variant='body2'>{currentEmployeeEmail}</Typography>
                    </Stack>
                    {isStandardLogin && (
                        <Stack>
                            <Typography variant='body2'>{t('user_settings.password')}</Typography>
                            <Stack direction='row'>
                                <Button disabled={loading || resetPasswordDisabled} onClick={() => resetPassword()}>
                                    {t(
                                        resetPasswordDisabled
                                            ? 'user_settings.send_reset_password_email_link_email_sent'
                                            : 'user_settings.send_reset_password_email',
                                    )}
                                </Button>
                            </Stack>
                        </Stack>
                    )}
                </Stack>
            </Paper>
            <Paper sx={{ p: 2 }}>
                <Typography variant='body2bold'>{t('user_settings.interface')}</Typography>
                <Stack direction='column' gap={2} pt={2}>
                    <FormControl component='fieldset'>
                        <Typography color='textSecondary' variant='body2'>
                            {t('user_settings.language')}
                        </Typography>

                        <RadioGroup row value={currentLanguage} onChange={handleLanguageChange}>
                            <FormControlLabel value={UserLanguage.FR} labelPlacement='end' control={<Radio />} label={'Français'} />
                            <FormControlLabel value={UserLanguage.EN} labelPlacement='end' control={<Radio />} label={'English'} />
                            <FormControlLabel value={UserLanguage.DE} labelPlacement='end' control={<Radio />} label={'Deutsch'} />
                            <FormControlLabel value={UserLanguage.ES} labelPlacement='end' control={<Radio />} label={'Español'} />
                            <FormControlLabel value={UserLanguage.PT} labelPlacement='end' control={<Radio />} label={'Português'} />
                        </RadioGroup>
                    </FormControl>
                </Stack>
            </Paper>
            <Stack component={Paper} p={2}>
                <Typography variant='body2bold'>{t('user_settings.notifications')}</Typography>
                <Stack direction='column' pt={2}>
                    {hasLeaveFeatureEnabled && (
                        <>
                            <FormControlLabel
                                label={t('user_settings.leave_updates')}
                                labelPlacement={'end'}
                                control={
                                    <Checkbox
                                        checked={employeeNotification?.leaveNotificationsEnabled}
                                        onChange={event => handleNotificationChange({ leaveNotificationsEnabled: event.target.checked })}
                                        size='small'
                                    />
                                }
                            />
                            <FormControlLabel
                                label={t('user_settings.leave_attachments_updates')}
                                labelPlacement={'end'}
                                control={
                                    <Checkbox
                                        checked={employeeNotification?.leaveAttachmentNotificationsEnabled}
                                        onChange={event => handleNotificationChange({ leaveAttachmentNotificationsEnabled: event.target.checked })}
                                        size='small'
                                    />
                                }
                            />
                        </>
                    )}
                    {isPlanningFeatureEnabled && (
                        <FormControlLabel
                            label={t('user_settings.planning_updates')}
                            labelPlacement={'end'}
                            control={
                                <Checkbox
                                    checked={employeeNotification?.planningUpdatesNotificationsEnabled}
                                    onChange={event => handleNotificationChange({ planningUpdatesNotificationsEnabled: event.target.checked })}
                                    size='small'
                                />
                            }
                        />
                    )}
                    {isDocumentFeatureEnabled && (
                        <FormControlLabel
                            label={t('user_settings.documents_updates')}
                            labelPlacement={'end'}
                            control={
                                <Checkbox
                                    checked={employeeNotification?.documentNotificationsEnabled}
                                    onChange={event => handleNotificationChange({ documentNotificationsEnabled: event.target.checked })}
                                    size='small'
                                />
                            }
                        />
                    )}
                    {isAdvancedProfileFeatureEnabled && (
                        <FormControlLabel
                            label={t('user_settings.profile_pending_updates')}
                            labelPlacement={'end'}
                            control={
                                <Checkbox
                                    checked={employeeNotification?.profileChangeNotificationsEnabled}
                                    onChange={event => handleNotificationChange({ profileChangeNotificationsEnabled: event.target.checked })}
                                    size='small'
                                />
                            }
                        />
                    )}
                    {isTimesheetFeatureEnabled && (
                        <FormControlLabel
                            label={t('user_settings.timesheets_updates')}
                            labelPlacement={'end'}
                            control={
                                <Checkbox
                                    checked={employeeNotification?.timesheetUpdatesNotificationsEnabled}
                                    onChange={event => handleNotificationChange({ timesheetUpdatesNotificationsEnabled: event.target.checked })}
                                    size='small'
                                />
                            }
                        />
                    )}
                    <FormControlLabel
                        label={t('user_settings.announcements')}
                        labelPlacement={'end'}
                        control={
                            <Checkbox
                                checked={employeeNotification.announcementNotificationsEnabled}
                                onChange={event => handleNotificationChange({ announcementNotificationsEnabled: event.target.checked })}
                                size='small'
                            />
                        }
                    />
                </Stack>
            </Stack>
        </Stack>
    );
};
