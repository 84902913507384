import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { Job } from '@/domain/job/Job.model';
import { deleteJob } from '@/domain/job/Job.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { useGetJobs } from '@/hooks/job/Job.hook';
import { useRealmFeatureEnabled } from '@/hooks/realm/useRealmFeatureEnabled';
import { CompanySettingsLayout } from '@/page/setting/CompanySettingsLayout';
import { ConfigType } from '@/page/setting/types';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { ColDef, ICellRendererParams } from 'ag-grid-community';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';

export const JobSettingsPage: FC = () => {
    const { t } = useTranslation();
    const isJobFamiliesFeatureEnabled = useRealmFeatureEnabled(RealmFeaturesType.JOB_FAMILIES);
    const { data: jobs = [], isError, isLoading, error, refetch: refetchJobs } = useGetJobs();

    const handleDelete = async (params: ICellRendererParams<Job>) => {
        if (!params.data?.id) {
            return;
        }
        try {
            await deleteJob(params.data.id);
            await refetchJobs();
        } catch (e) {
            handleError(e);
        }
    };

    const items = (params: ICellRendererParams<Job>): BasicMenuItem[] => {
        return [
            {
                title: t('general.delete'),
                onClick: () => {
                    handleDelete(params);
                },
            },
        ];
    };

    const getMoreVerticalButtons = (params: ICellRendererParams<Job>) => <BasicMenu items={items(params)} />;

    const config: ConfigType<Job> = {
        type: 'table',
        header: {
            primaryButtonCaption: `${t('general.add')} ${t('job_settings_page.job')}`,
        },
        isOnRowClickActive: true,
        table: {
            columns: [
                {
                    headerName: t('job_settings_page.table_header.name'),
                    field: 'name',
                    valueGetter: ({ data }) => {
                        return getLabelTranslation(data?.name);
                    },
                },
            ] satisfies ColDef<Job>[],
        },
    };

    if (isJobFamiliesFeatureEnabled) {
        config.table?.columns?.push({
            field: 'jobFamily.name',
            headerName: t('job_settings_page.table_header.job_family'),
        });
        config.table?.columns?.push({
            headerName: t('job_settings_page.table_header.job_level'),
            field: 'jobLevel.name',
            type: 'label',
        });
    }
    config.table?.columns?.push({
        type: 'actionMenu',
        cellRenderer: getMoreVerticalButtons,
    });

    return (
        <StateHandler isLoading={isLoading} isError={isError} error={error}>
            <CompanySettingsLayout options={config} data={jobs} isSearchAvailable={true} />
        </StateHandler>
    );
};
