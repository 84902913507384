import { FC, useState } from 'react';
import { DialogWrapper, DialogWrapperProps } from '@/components/dialog-wrapper/DialogWrapper';
import DialogContent from '@mui/material/DialogContent/DialogContent';
import { useTranslation } from 'react-i18next';
import { Box, Button, DialogActions, FormControlLabel, Stack, Typography } from '@mui/material';
import { DepartmentNode, DepartmentUpdateMutation } from '@/domain/department/Department.model';
import { FieldSwitch } from '@/components/form/field-switch/FieldSwitch';
import { FieldLocalDate } from '@/components/form/field-date/FieldDate';
import { ArrowRight02Icon } from 'hugeicons-react';
import { useForm, useWatch } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { getLocalDateTestConfig, isAfterOrEqualDate, isValidDate } from '@/utils/datetime.util';
import {
    archiveDepartment,
    createDepartment,
    moveDepartmentToNewParent,
    updateDepartment,
    updateDepartmentOrders,
} from '@/domain/department/Department.service';
import { handleError } from '@/utils/api.util';
import { EmployeeAvatar } from '@/domain/employee/Employee.model';
import {
    Employment,
    EmploymentBulkCreateMutationItem,
    EmploymentBulkCreationMutation,
    EmploymentBulkMutation,
    EmploymentBulkUpdateMutationItem,
    EmploymentCreateReason,
} from '@/domain/employment/Employment.model';
import { bulkCreateEmployment } from '@/domain/employment/Employment.service';
import { getLabelTranslation } from '@/utils/language.util';
import { StackedAvatars } from '@/domain-ui/employee/stacked-avatar/StackedAvatars';
import { findRecursiveItemById, findRecursiveItemParent, findRecursiveItemParents } from '@/utils/object.util';
import { DepartmentEmployeeSelectionDialog } from '@/page/setting/organization/department/employment-update-dialog/DepartmentEmployeeSelectionDialog';
import { useGetEmployeesAffectedByDepartmentChange } from '@/page/setting/organization/department/employment-update-dialog/useGetEmployeesByDepartment';
import { DepartmentFormValues } from '@/page/setting/organization/department/department-dialog/DepartmentDialog.schema';
import { formatCostCentersAssignment, isSameCostCenters } from '@/domain/cost-center/CostCenter.service';
import { isEqualArray } from '@/utils/collections.util';

export type DepartmentUpdateEmploymentDialogProps = {
    departmentNodes: DepartmentNode[];
    departmentNode: DepartmentNode;
    newValue: Pick<DepartmentFormValues, 'name' | 'managers' | 'departmentCostCenters'> & {
        parentId: number | undefined;
        order: number;
    };
    onSave: () => void;
} & DialogWrapperProps;

export const DepartmentEmploymentManagementDialog: FC<DepartmentUpdateEmploymentDialogProps> = ({
    departmentNodes,
    departmentNode,
    newValue,
    onSave,
    ...restDialog
}) => {
    const newParentId = newValue.parentId;
    const { t } = useTranslation();

    const { control, handleSubmit } = useForm({
        resolver: yupResolver(getSchema()),
        defaultValues: {
            affectEmployments: false,
        },
    });
    const [affectEmploymentsWatch, effectiveDateWatch] = useWatch({
        control,
        name: ['affectEmployments', 'effectiveDate'],
    });

    // check if the date is Valid because during the edition of the field date
    // the value is not valid until the user has finished to write the date
    const enableSearchEmployees = affectEmploymentsWatch && isValidDate(effectiveDateWatch);

    // search employees that have a current or future employment in department
    const {
        employeesInDepartment,
        isFetching: isEmployeesFetching,
        selectedEmployees: affectedEmployees,
        onSelectedEmployeesChange,
    } = useGetEmployeesAffectedByDepartmentChange(departmentNode.id, effectiveDateWatch, {
        enabled: enableSearchEmployees,
    });

    // filter out the managers to avoid creating an employment with himself as manager
    const cleanEmployeesInDepartment = employeesInDepartment.filter(e => !newValue.managers.some(m => m.id === e.id));
    const cleanAffectedEmployees = affectedEmployees.filter(e => !newValue.managers.some(m => m.id === e.id));

    // find the previous and new parents
    const previousParentItem = findRecursiveItemParent(departmentNodes, departmentNode.id);
    const previousParents = findRecursiveItemParents(departmentNodes, departmentNode.id);
    const newParentItem = newParentId ? findRecursiveItemById(departmentNodes, newParentId) : undefined;
    const newParents = newParentItem ? [...findRecursiveItemParents(departmentNodes, newParentItem.id), newParentItem] : undefined;

    // boolean to check what has changed
    const parentHasChanged = previousParentItem?.id !== newParentId;
    const managerHasChanged = !isEqualArray(
        departmentNode.managers.map(m => m.id),
        newValue.managers.map(m => m.id),
    );
    const costCentersHasChanged = !isSameCostCenters(departmentNode.departmentCostCenters, newValue.departmentCostCenters);

    const handleSave = async ({ affectEmployments, effectiveDate }: DepartmentMoveFormValues) => {
        try {
            const shouldUpdateEmployments = affectEmployments && !!effectiveDate;
            if (shouldUpdateEmployments) {
                await applyChangesAtDate(effectiveDate);
            } else {
                await applyChangesRetroactively();
            }
            onSave();
        } catch (error) {
            handleError(error);
        }
    };

    const applyChangesAtDate = async (effectiveDate: LocalDate) => {
        if (parentHasChanged) {
            // In that case, we create archive the department and create a new one with the new values
            const newDepartmentId = await archiveAndCreateNewDepartment({
                departmentNodes,
                departmentNode,
                newValue,
            });
            // and we update the employments on the new department created
            await updateEmployments(newDepartmentId, effectiveDate);
        } else {
            // Otherwise, we change the values and update the employments on the same department
            await updateDepartmentValues();
            await updateEmployments(departmentNode.id, effectiveDate);
        }
    };

    const applyChangesRetroactively = async () => {
        // 1- move the department if the parent has changed
        if (parentHasChanged) {
            // when the parent has changed, we need to update the department with the new parent
            // and reorder items
            await moveDepartmentToNewParent({
                departmentNodes,
                departmentNode,
                newParentId: newValue.parentId,
                newIndex: newValue.order,
            });
        }

        // 2- update the department values if the managers or cost centers have changed
        if (managerHasChanged || costCentersHasChanged) {
            await updateDepartmentValues();
        }
    };

    // Update department value and return a promise with the departmentId
    const updateDepartmentValues = async () => {
        const mutation: DepartmentUpdateMutation = {
            name: newValue.name,
            parentId: newValue.parentId, // here the parentId is the same
            managerIds: newValue.managers.map(m => m.id),
            departmentCostCenters: newValue.departmentCostCenters.map(dcc => ({
                costCenterId: dcc.costCenter.id,
                percentage: dcc.percentage,
            })),
            order: newValue.order,
        };
        return updateDepartment(departmentNode.id, mutation).then(dep => dep.id);
    };

    // update/create employments for each affected employee
    const updateEmployments = async (departmentId: number, effectiveDate: LocalDate) => {
        const employmentsAffected = affectedEmployees.flatMap(e => e.employments);
        // Create or update employment for each employee
        const bulkMutations = buildBulkEmploymentMutations({
            employments: employmentsAffected,
            newDepartmentValue: newValue,
            departmentId,
            effectiveDate,
        });
        if (bulkMutations.length) {
            await bulkCreateEmployment(bulkMutations);
        }
    };

    const getFullPath = (parents: DepartmentNode[]) => {
        const allNodeNames = [...parents.map(p => getLabelTranslation(p.name)), getLabelTranslation(newValue.name)];
        return allNodeNames.join(' / ');
    };

    return (
        <DialogWrapper {...restDialog} header={t('settings_organization.departments.update_employments_dialog.title')} maxWidth={'md'}>
            <Stack gap={3} component={DialogContent}>
                {parentHasChanged && (
                    <Stack>
                        <Typography variant={'body1bold'} sx={{ mb: 1 }}>
                            {t('settings_organization.departments.update_employments_dialog.parents_changes')}
                        </Typography>

                        <DiffText prevValue={getFullPath(previousParents)} newValue={getFullPath(newParents ?? [])} />
                    </Stack>
                )}
                {managerHasChanged && (
                    <Stack>
                        <Typography variant={'body1bold'} sx={{ mb: 1 }}>
                            {t('settings_organization.departments.update_employments_dialog.managers')}
                        </Typography>
                        <DiffAvatar prevValue={departmentNode.managers} newValue={newValue.managers} />
                    </Stack>
                )}
                {costCentersHasChanged && (
                    <Stack>
                        <Typography variant={'body1bold'} sx={{ mb: 1 }}>
                            {t('settings_organization.departments.update_employments_dialog.cost_centers')}
                        </Typography>
                        <DiffText
                            prevValue={formatCostCentersAssignment(departmentNode.departmentCostCenters)}
                            newValue={formatCostCentersAssignment(newValue.departmentCostCenters)}
                        />
                    </Stack>
                )}

                <FieldSwitch
                    name={'affectEmployments'}
                    control={control}
                    label={t('settings_organization.departments.update_employments_dialog.affect_employments')}
                    labelPlacement={'end'}
                />
                {affectEmploymentsWatch && (
                    <FormControlLabel
                        control={<FieldLocalDate control={control} name={'effectiveDate'} />}
                        label={t('settings_organization.departments.update_employments_dialog.effective_date')}
                    />
                )}
                {enableSearchEmployees && !isEmployeesFetching && (
                    <EmployeesAffected employees={cleanEmployeesInDepartment} values={cleanAffectedEmployees} onChange={onSelectedEmployeesChange} />
                )}
            </Stack>
            <DialogActions>
                <Button onClick={handleSubmit(handleSave, console.error)} fullWidth={true}>
                    {t('general.confirm')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};

const EmployeesAffected: FC<{
    employees: EmployeeAvatar[];
    values: EmployeeAvatar[];
    onChange: (employees: EmployeeAvatar[]) => void;
}> = ({ employees, values, onChange }) => {
    const [openEmployeeSelectionDialog, setOpenEmployeeSelectionDialog] = useState(false);
    const { t } = useTranslation();

    const handleAffectedEmployeesChange = (selectedEmployeeIds: EmployeeAvatar[]) => {
        onChange(selectedEmployeeIds);
        setOpenEmployeeSelectionDialog(false);
    };

    return (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Typography>
                {t('settings_organization.departments.update_employments_dialog.affected_employees', {
                    selectedEmployees: values.length,
                    totalEmployees: employees.length,
                })}
            </Typography>
            <Stack direction={'row'} alignItems={'center'}>
                <StackedAvatars employeeAvatars={values} />

                {employees.length > 0 && (
                    <Button onClick={() => setOpenEmployeeSelectionDialog(true)} size={'small'} variant={'text'}>
                        {t('settings_organization.departments.update_employments_dialog.chooseEmployees')}
                    </Button>
                )}
                {openEmployeeSelectionDialog && (
                    <DepartmentEmployeeSelectionDialog
                        employees={employees}
                        defaultValues={values}
                        onSave={handleAffectedEmployeesChange}
                        open={openEmployeeSelectionDialog}
                        onClose={() => setOpenEmployeeSelectionDialog(false)}
                    />
                )}
            </Stack>
        </Stack>
    );
};

const DiffText: FC<{ prevValue: string; newValue: string }> = ({ prevValue, newValue }) => {
    return (
        <Stack direction={'row'} alignItems={'center'} gap={2}>
            <Typography
                variant={'body1bold'}
                sx={{
                    flex: 1,
                    textDecoration: prevValue ? 'line-through' : 'none',
                    color: 'red',
                }}
            >
                {prevValue || '-'}
            </Typography>
            <ArrowRight02Icon />
            <Typography
                sx={{
                    flex: 1,
                }}
            >
                {newValue || '-'}
            </Typography>
        </Stack>
    );
};

const DiffAvatar: FC<{ prevValue: EmployeeAvatar[]; newValue: EmployeeAvatar[] }> = ({ prevValue, newValue }) => {
    return (
        <Stack direction={'row'} alignItems={'center'} justifyContent={'space-between'} gap={2}>
            <Box flex={1}>{prevValue.length ? <StackedAvatars employeeAvatars={prevValue} sx={{ flex: 1 }} /> : '-'}</Box>
            <ArrowRight02Icon />
            <Box flex={1}>{newValue.length ? <StackedAvatars employeeAvatars={newValue} /> : '-'}</Box>
        </Stack>
    );
};

const getSchema = () => {
    return yup.object().shape({
        affectEmployments: yup.boolean().required().default(false),
        effectiveDate: yup
            .string<LocalDate>()
            .test(getLocalDateTestConfig())
            .when('affectEmployments', {
                is: true,
                then: schema => schema.required(),
            }),
    });
};
type DepartmentMoveFormValues = yup.InferType<ReturnType<typeof getSchema>>;

const archiveAndCreateNewDepartment = async ({
    departmentNodes,
    departmentNode,
    newValue,
}: {
    departmentNodes: DepartmentNode[];
    departmentNode: DepartmentNode;
    newValue: DepartmentUpdateEmploymentDialogProps['newValue'];
}) => {
    const newParentId = newValue.parentId;
    const newIndex = newValue.order;

    try {
        // 1- Create a new department, same name but different parent
        const newDepartment = await createDepartment({
            name: newValue.name,
            parentId: newParentId,
            managerIds: newValue.managers.map(m => m.id),
            departmentCostCenters: newValue.departmentCostCenters.map(dcc => ({
                costCenterId: dcc.costCenter.id,
                percentage: dcc.percentage,
            })),
            order: newIndex,
        });

        // 2- archive the moved department
        await archiveDepartment(departmentNode.id);

        // 3- Reorder the new branch
        // build mutations of the new children with the moved item
        // an undefined parentId means the department is at the root
        const childrenDestination = newParentId ? (findRecursiveItemById(departmentNodes, newParentId)?.children ?? []) : departmentNodes;
        const newChildrenDestination = [...childrenDestination];
        newChildrenDestination.splice(newIndex, 0, departmentNode); // insert the moved item

        const childrenDestinationOrderMutation = newChildrenDestination.map((child, index) => ({
            resourceId: child.id,
            order: index,
        }));
        await updateDepartmentOrders(childrenDestinationOrderMutation);
        return newDepartment.id;
    } catch (error) {
        handleError(error);
    }
    return departmentNode.id;
};

const buildBulkEmploymentMutations = ({
    employments,
    departmentId,
    newDepartmentValue,
    effectiveDate,
}: {
    employments: Employment[];
    departmentId: number;
    newDepartmentValue: DepartmentUpdateEmploymentDialogProps['newValue'];
    effectiveDate: LocalDate;
}) => {
    return employments.reduce<EmploymentBulkMutation[]>((acc, employment) => {
        // If the employment start after effective or at the same day, we update the department to have the new values => UPDATE
        // For ongoing employments, we clone the principal employment with the new values => CREATE
        const isUpdate = isAfterOrEqualDate(employment.startDate, effectiveDate);

        if (isUpdate) {
            const updateRequest = {
                // updated attributes
                departmentId,
                employmentCostCenters: newDepartmentValue.departmentCostCenters.map(dcc => ({
                    costCenterId: dcc.costCenter.id,
                    percentage: dcc.percentage,
                })),
                managerIds: newDepartmentValue.managers.map(m => m.id),
                // fill values from the employment
                locationId: employment.location.id,
                jobId: employment.job.id,
                jobFamilyId: employment.jobFamily?.id,
            } satisfies EmploymentBulkUpdateMutationItem;

            return [
                ...acc,
                {
                    employeeId: employment.employeeId,
                    employmentId: employment.id,
                    action: 'UPDATE',
                    updateRequest: updateRequest,
                } satisfies EmploymentBulkMutation,
            ];
        }

        const createRequest = {
            // updated attributes
            departmentId,
            startDate: effectiveDate,
            employmentCostCenters: newDepartmentValue.departmentCostCenters.map(dcc => ({
                costCenterId: dcc.costCenter.id,
                percentage: dcc.percentage,
            })),
            managerIds: newDepartmentValue.managers.map(m => m.id),
            // fill values from the employment
            employmentCreateReason: EmploymentCreateReason.NEW_ENTITY,
            principal: employment.principal,
            contractType: employment.contractType,
            locationId: employment.location.id,
            jobId: employment.job.id,
            jobFamilyId: employment.jobFamily?.id,
            // we clone only the employment principal, the percentage is always 100
            percentage: 100,
        } satisfies EmploymentBulkCreateMutationItem;

        const existsMutation = acc.find(m => m.employeeId === employment.employeeId && m.action === 'CREATE');

        if (existsMutation) {
            (existsMutation as EmploymentBulkCreationMutation).creationRequests.push(createRequest);
            return acc;
        } else {
            return [
                ...acc,
                {
                    employeeId: employment.employeeId,
                    action: 'CREATE',
                    creationRequests: [createRequest],
                } satisfies EmploymentBulkMutation,
            ];
        }
    }, [] as EmploymentBulkMutation[]);
};
