import { Employee, EmployeeAuthentication, EmployeePolicy } from '@/domain/employee/Employee.model';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LoginMethod } from '@/domain/realm/Realm.model';
import { UserLanguage } from '@/utils/language.util';

export type CurrentEmployeeState = {
    authenticated: boolean;
    email: string | undefined;
    language: UserLanguage | undefined;
    employee: Employee | undefined;
    loginMethod: LoginMethod | undefined;
    grantedPolicies: EmployeePolicy[];
};

const initialState: CurrentEmployeeState = {
    authenticated: false,
    language: undefined,
    email: undefined,
    employee: undefined,
    loginMethod: undefined,
    grantedPolicies: [],
};

export const currentEmployeeSlice = createSlice({
    name: 'currentEmployee',
    // `createSlice` will infer the state type from the `initialState` argument
    initialState,
    reducers: {
        employeeAuthenticated: (state: CurrentEmployeeState, action: PayloadAction<EmployeeAuthentication>) => {
            return {
                ...state,
                authenticated: true,
                email: action.payload?.email,
                language: action.payload?.language,
                employee: action.payload?.employee,
                loginMethod: action.payload?.loginMethod,
                grantedPolicies: action.payload?.grantedPolicies,
            };
        },
        employeeSignedOut: (state: CurrentEmployeeState) => ({
            ...state,
            authenticated: false,
            email: undefined,
            language: undefined,
            employee: undefined,
            loginMethod: undefined,
            grantedPolicies: [],
            permissionGroups: [],
        }),
        employeeLanguageUpdated: (state: CurrentEmployeeState, action: PayloadAction<UserLanguage>) => ({
            ...state,
            language: action.payload,
        }),
    },
});

export const { employeeAuthenticated, employeeSignedOut, employeeLanguageUpdated } = currentEmployeeSlice.actions;

export const currentEmployeeReducer = currentEmployeeSlice.reducer;
