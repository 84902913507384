import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import {
    EmployeeReview,
    EmployeeReviewApproveMutation,
    EmployeeReviewer,
    EmployeeReviewReminder,
    EmployeeReviewSearch,
    UpdateEmployeeReviewMutation,
} from '@/domain/employee-review/EmployeeReview.model';
import { EmployeeReviewStatus } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { EmployeeAvatarDTO, EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import { ReviewDTO } from '@/api/review/Review.api';
import { Review, ReviewNotificationType } from '@/domain/review/Review.model';
import { mapReviewTemplateDTO } from '@/api/review-template/ReviewTemplate.api';
import { reviewRatingScaleApi } from '@/api/review-rating-scale/ReviewRatingScale.api';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';

export const mapEmployeeReviewerDTO = (employeeReviewer: EmployeeReviewerDTO): EmployeeReviewer => {
    return {
        ...employeeReviewer,
        reviewedAt: convertUTCIsoStringToDate(employeeReviewer?.reviewedAt),
        employee: employeeReviewer.employee,
    };
};

export const mapEmployeeReminderDTO = (employeeReviewReminder: EmployeeReviewReminderDTO): EmployeeReviewReminder => {
    return {
        ...employeeReviewReminder,
        sentAt: convertUTCIsoStringToDate(employeeReviewReminder.sentAt),
    };
};

export type EmployeeReviewerDTO = StrictOmit<EmployeeReviewer, 'reviewedAt' | 'employee'> & {
    reviewedAt: string;
    employee: EmployeeAvatarDTO;
};

export type EmployeeReviewReminderDTO = {
    recipient: EmployeeAvatarDTO;
    type: ReviewNotificationType;
    sentAt: string;
};

export type EmployeeReviewDTO = Overwrite<
    EmployeeReview,
    {
        discussionStartedAt: string;
        discussionSubmittedAt: string;
        selfReviewedAt: string;
        upwardReviewers: EmployeeReviewerDTO[];
        managers: EmployeeReviewerDTO[];
        peerReviewers: EmployeeReviewerDTO[];
        employee: EmployeeDTO;
        review: ReviewDTO | undefined;
        reminders: EmployeeReviewReminderDTO[];
    }
>;

type EmployeeReviewSearchRequest = EmployeeReviewSearch;
type UpdateEmployeeReviewRequest = UpdateEmployeeReviewMutation;
type EmployeeReviewApproveRequest = EmployeeReviewApproveMutation;

type EmployeeReviewCloseRequest = {
    comment: string;
};

const BASE_URL = API_BASE_URL + '/employee-reviews';

export const mapEmployeeReviewDTO = (employeeReview: EmployeeReviewDTO): EmployeeReview => {
    return {
        ...employeeReview,
        discussionStartedAt: convertUTCIsoStringToDate(employeeReview?.discussionStartedAt),
        discussionSubmittedAt: convertUTCIsoStringToDate(employeeReview?.discussionSubmittedAt),
        selfReviewedAt: convertUTCIsoStringToDate(employeeReview.selfReviewedAt),
        upwardReviewers: employeeReview.upwardReviewers?.map(mapEmployeeReviewerDTO),
        managers: employeeReview.managers?.map(mapEmployeeReviewerDTO),
        peerReviewers: employeeReview.peerReviewers?.map(mapEmployeeReviewerDTO),
        review: employeeReview?.review ? mapReviewDTO(employeeReview?.review) : undefined,
        employee: mapEmployeeDTO(employeeReview.employee),
        reminders: employeeReview.reminders?.map(mapEmployeeReminderDTO),
    };
};

/**
 * This function is solely utilized within the mapEmployeeReviewDTO function to prevent an infinite loop scenario
 * that could occur when mapping between Review and EmployeeReview objects.
 * By avoiding direct circular references between Review and EmployeeReview objects,
 * we prevent infinite recursion in the mapping process.
 * review -> employeeReview -> review -> employeeReview ...
 */
const mapReviewDTO = (review: ReviewDTO): Review => {
    return {
        ...review,
        items: review.items.map(item => ({
            ...item,
            rating: item.rating ? reviewRatingScaleApi.mapToReviewRatingScale(item.rating) : undefined,
        })),
        reviewTemplate: review.reviewTemplate ? mapReviewTemplateDTO(review.reviewTemplate) : undefined,
        employeeReviews: [],
        owners: review.owners ?? [],
    };
};

async function getEmployeeReview(employeeReviewId: number): Promise<EmployeeReview> {
    const { data } = await client.get<EmployeeReviewDTO>(BASE_URL + `/${employeeReviewId}`);

    return mapEmployeeReviewDTO(data);
}

async function updateEmployeeReview(employeeReviewId: number, employeeReview: UpdateEmployeeReviewMutation): Promise<EmployeeReview> {
    const { data } = await client.put<EmployeeReviewDTO, AxiosResponse<EmployeeReviewDTO, UpdateEmployeeReviewRequest>>(
        BASE_URL + `/${employeeReviewId}`,
        employeeReview,
    );

    return mapEmployeeReviewDTO(data);
}

async function submitEmployeeReviewDiscussion(employeeReviewId: number): Promise<EmployeeReview> {
    const { data } = await client.post<EmployeeReviewDTO, AxiosResponse<EmployeeReviewDTO>>(BASE_URL + `/${employeeReviewId}/submit-discussion`);

    return mapEmployeeReviewDTO(data);
}

async function startEmployeeReviewDiscussion(employeeReviewId: number): Promise<EmployeeReview> {
    const { data } = await client.post<EmployeeReviewDTO, AxiosResponse<EmployeeReviewDTO>>(BASE_URL + `/${employeeReviewId}/start-discussion`);
    return mapEmployeeReviewDTO(data);
}

async function closeEmployeeReview(employeeReviewId: number, request: EmployeeReviewCloseRequest): Promise<EmployeeReview> {
    const { data } = await client.post<EmployeeReviewDTO, AxiosResponse<EmployeeReviewDTO, EmployeeReviewCloseRequest>>(
        BASE_URL + `/${employeeReviewId}/close`,
        request,
    );
    return mapEmployeeReviewDTO(data);
}

async function cancelEmployeeReview(employeeReviewId: number): Promise<EmployeeReview> {
    const { data } = await client.post<EmployeeReviewDTO, AxiosResponse<EmployeeReviewDTO>>(BASE_URL + `/${employeeReviewId}/cancel`);
    return mapEmployeeReviewDTO(data);
}

type EmployeeReviewResetRequestDTO = {
    status: EmployeeReviewStatus;
};

async function resetEmployeeReview(employeeReviewId: number, request: EmployeeReviewResetRequestDTO): Promise<EmployeeReview> {
    const { data } = await client.post<EmployeeReviewDTO, AxiosResponse<EmployeeReviewDTO, EmployeeReviewResetRequestDTO>>(
        BASE_URL + `/${employeeReviewId}/reset`,
        request,
    );
    return mapEmployeeReviewDTO(data);
}

async function approveEmployeeReview(employeeReviewId: number, request: EmployeeReviewApproveMutation): Promise<EmployeeReview> {
    const { data } = await client.post<EmployeeReviewDTO, AxiosResponse<EmployeeReviewDTO, EmployeeReviewApproveRequest>>(
        BASE_URL + `/${employeeReviewId}/approve`,
        request,
    );
    return mapEmployeeReviewDTO(data);
}

async function searchEmployeeReviews(employeeReviewSearch: EmployeeReviewSearch): Promise<EmployeeReview[]> {
    const { data } = await client.post<EmployeeReviewDTO[], AxiosResponse<EmployeeReviewDTO[]>, EmployeeReviewSearchRequest>(
        BASE_URL + `/search`,
        employeeReviewSearch,
    );
    return data.map(mapEmployeeReviewDTO);
}

async function searchEmployeeReviewAsContributor(employeeReviewSearch: EmployeeReviewSearch): Promise<EmployeeReview[]> {
    const { data } = await client.post<EmployeeReviewDTO[], AxiosResponse<EmployeeReviewDTO[]>, EmployeeReviewSearchRequest>(
        BASE_URL + `/reviewer/search`,
        employeeReviewSearch,
    );
    return data.map(mapEmployeeReviewDTO);
}

export const employeeReviewApi = {
    getEmployeeReview,
    updateEmployeeReview,
    submitEmployeeReviewDiscussion,
    startEmployeeReviewDiscussion,
    closeEmployeeReview,
    cancelEmployeeReview,
    resetEmployeeReview,
    approveEmployeeReview,
    searchEmployeeReviews,
    searchEmployeeReviewAsContributor,
};
