import { BugsnagErrorCatcher } from '@/bugsnag';
import { Typography } from '@mui/material';
import { FC, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';

/**
 * ErrorBoundary component that will catch any error that is thrown in the children components and send it to Bugsnag
 * @returns {ReactNode} The children components
 */
export const ErrorBoundary: FC<PropsWithChildren<{ fallbackComponent?: FC<TypographyErrorProps> }>> = ({ children, fallbackComponent }) => {
    const FallbackComponent = fallbackComponent || TypographyError;
    // Will catch any error that is thrown in the children components and send it to Bugsnag
    return <BugsnagErrorCatcher FallbackComponent={FallbackComponent}>{children}</BugsnagErrorCatcher>;
};

export const WithErrorBoundary = <P extends object>(WrappedComponent: FC<P>): FC<P> => {
    const ComponentWithErrorBoundary: FC<P> = props => (
        <ErrorBoundary>
            <WrappedComponent {...props} />
        </ErrorBoundary>
    );

    ComponentWithErrorBoundary.displayName = `WithErrorBoundary(${WrappedComponent.displayName ?? (WrappedComponent.name || 'Component')})`;

    return ComponentWithErrorBoundary;
};
type TypographyErrorProps = {
    error: Error;
};

const TypographyError: FC<TypographyErrorProps> = ({ error }) => {
    const { t } = useTranslation();

    console.error('ErrorBoundary error: ', error);
    return <Typography variant='body2bold'>{t('general.errors.default_title')}</Typography>;
};
