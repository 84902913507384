import { EmployeePolicy } from '@/domain/employee/Employee.model';
import {
    canAccessEmployeeReviews,
    canManageLeaves,
    canViewEmployeeDocuments,
    canViewEmployeeSkills,
    canViewObjectives,
    canViewProfileJobDescriptionTab,
    canViewProfileJobInfoTab,
    canViewProfilePersonalInfoTab,
    canViewShifts,
    canViewTimesheets,
} from '@/domain/permission/Permission.service';
import { RealmFeature } from '@/domain/realm/Realm.model';
import { useGetSectionDefinitions } from '@/hooks/section-definition/SectionDefinition.hook';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { Page } from '@/page/Pages';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';

// if we don't use specific file for employee profile pages, we have this error
// ReferenceError: Cannot access 'RouterBoundary' before initialization
// in the cypress test of EmployeeProfileLayout, Employee profile card, Employee profile page
export const useGetProfilePages = (): Page[] => {
    const employeeId = useEmployeeProfileId();
    const policies = useCurrentPolicies();
    const realmFeatures = useCurrentRealm().realmFeatures;
    const { data: sectionDefinitions = [] } = useGetSectionDefinitions();

    const allPages: Page[] = [
        {
            labelKey: 'my_profile.tabs.personal_info',
            path: '/profile/personal-info',
            condition: (_, policies: EmployeePolicy[]) =>
                canViewProfilePersonalInfoTab(
                    policies,
                    employeeId,
                    sectionDefinitions.map(section => section.id),
                ),
        },
        {
            labelKey: 'my_profile.tabs.job_info',
            path: '/profile/job-info',
            condition: (_, policies: EmployeePolicy[]) => canViewProfileJobInfoTab(policies, employeeId),
        },
        {
            labelKey: 'my_profile.tabs.leaves',
            path: '/profile/leaves',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canManageLeaves(realmFeatures, policies, employeeId),
        },
        {
            labelKey: 'my_profile.tabs.timesheets',
            path: '/profile/timesheets',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewTimesheets(realmFeatures, policies, employeeId),
        },
        {
            labelKey: 'my_profile.tabs.planning',
            path: '/profile/planning',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewShifts(realmFeatures, policies),
        },
        {
            labelKey: 'my_profile.tabs.reviews',
            path: '/profile/reviews',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canAccessEmployeeReviews(realmFeatures, policies, employeeId),
        },
        {
            labelKey: 'my_profile.tabs.job_description',
            path: '/profile/job-description',
            condition: (_, policies: EmployeePolicy[]) => canViewProfileJobDescriptionTab(policies, employeeId),
        },
        {
            labelKey: 'my_profile.tabs.skills',
            path: '/profile/skills',
            condition: (_, policies: EmployeePolicy[]) => canViewEmployeeSkills(policies, employeeId),
        },
        {
            labelKey: 'my_profile.tabs.objectives',
            path: '/profile/objectives',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewObjectives(realmFeatures, policies, employeeId),
        },
        {
            labelKey: 'my_profile.tabs.documents',
            path: '/profile/documents',
            condition: (realmFeatures: RealmFeature[], policies: EmployeePolicy[]) => canViewEmployeeDocuments(realmFeatures, policies, employeeId),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(realmFeatures, policies));
};
