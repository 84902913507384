import { Font, View } from '@react-pdf/renderer';
import { FC } from 'react';
import { Objective } from '@/domain/objective/Objective.model';
import { EmployeeReviewFeedback } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { FeedbackQuestionPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackQuestionPDF';
import { FeedbackSectionPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackSectionPDF';
import { FeedbackObjectivesPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackObjectivePDF';
import { FeedbackItemsType } from '@/page/review/employee-review-feedback-form/FeedbackPage.schema';
import { FeedbackSkillsPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackSkillsPDF';
import { useTheme } from '@mui/material';
import { FeedbackLayoutPDF } from '@/page/review/employee-review-feedback-form-pdf/FeedbackLayoutPDF';
import { EmployeeReview } from '@/domain/employee-review/EmployeeReview.model';
import { EmployeeReviewAction } from '@/domain/employee-review-action/EmployeeReviewAction.model';
import { useTranslation } from 'react-i18next';

type FeedbackFormPDFProps = {
    feedback: EmployeeReviewFeedback;
    feedbackItems: FeedbackItemsType;
    employeeReview: EmployeeReview;
    objectives: Objective[];
    actions: EmployeeReviewAction[];
};

// To allow emoji rendering in PDF
Font.registerEmojiSource({
    format: 'png',
    url: 'https://cdnjs.cloudflare.com/ajax/libs/twemoji/14.0.2/72x72/',
});

export const FeedbackFormPDF: FC<FeedbackFormPDFProps> = ({ feedback, objectives, feedbackItems, employeeReview, actions }) => {
    return (
        <FeedbackLayoutPDF feedback={feedback} employeeReview={employeeReview}>
            <FeedbackItemsPDF objectives={objectives} feedbackItems={feedbackItems} actions={actions} />
        </FeedbackLayoutPDF>
    );
};

export const FeedbackItemsPDF: FC<Omit<FeedbackFormPDFProps, 'feedback' | 'employeeReview'>> = ({ objectives, feedbackItems, actions }) => {
    const theme = useTheme();
    return (
        <View
            style={{
                gap: theme.spacing(2),
            }}
        >
            {feedbackItems.map(feedbackItem => (
                <FeedbackItem key={feedbackItem.id} feedbackItem={feedbackItem} objectives={objectives} actions={actions} />
            ))}
        </View>
    );
};

const FeedbackItem: FC<{
    feedbackItem: FeedbackItemsType[number];
    objectives: Objective[];
    actions: EmployeeReviewAction[];
}> = ({ feedbackItem, objectives, actions }) => {
    const { t } = useTranslation();
    switch (feedbackItem.type) {
        case 'QUESTION':
        case 'SKILL':
            return <FeedbackQuestionPDF key={feedbackItem.id} questionItem={feedbackItem} rating={feedbackItem.rating} />;
        case 'SECTION':
            return <FeedbackSectionPDF key={feedbackItem.id} sectionItem={feedbackItem} />;
        case 'REVIEW_OBJECTIVES':
            return (
                <FeedbackObjectivesPDF
                    key={feedbackItem.id}
                    title={t('reviews.review_summary.objectives')}
                    objectives={objectives}
                    instruction={feedbackItem.instruction}
                />
            );
        case 'OBJECTIVE_ACTIONS':
            return (
                <FeedbackObjectivesPDF
                    key={feedbackItem.id}
                    title={t('reviews.write_feedback.create_objective_action_title')}
                    objectives={actions.map(action => action.objective)}
                    instruction={feedbackItem.instruction}
                />
            );
        case 'SKILLS':
            return <FeedbackSkillsPDF key={feedbackItem.id} skills={feedbackItem} />;
        default:
            return <></>;
    }
};
