import { RequestStatusChip } from '@/components/request-status-chip/RequestStatusChip';
import {
    formatDate,
    formatDurationInHours,
    formatInDefaultDate,
    formatToLocalDate,
    getCurrentLocalDate,
    getEndOfMonth,
    getMonthTranslationKey,
    getStartOfMonth,
    MONTHS,
} from '@/utils/datetime.util';

import { Box, Button, Paper, Stack, Tooltip, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, ReactElement, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';
import './timesheets-history/TimesheetsHistory.css';

import {
    canApproveRejectLeaveRequests,
    canApproveRejectTimesheets,
    canManageApprovedTimesheets,
    canManagePendingTimesheets,
    canManageTimesheetAdjustments,
    canManageTimesheetPayments,
    canSeeEmployeeLeaveTypes,
} from '@/domain/permission/Permission.service';

import { AgGridWrapper } from '@/components/ag-grid-wrapper/AgGridWrapper';
import { useAgGridWrapper } from '@/components/ag-grid-wrapper/useAgGridWrapper';
import { BasicMenu, BasicMenuItem } from '@/components/basic-menu/BasicMenu';
import { EmployeeAvatarWithDetails } from '@/domain-ui/employee/employee-avatar/EmployeeAvatarWithDetails';
import { Spinner } from '@/components/spinner/Spinner';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { getEmployeeLeaveTypePolicies } from '@/domain/employee-leave-type/EmployeeLeaveType.service';
import { EmployeePayrollLock } from '@/domain/employee-payroll-lock/EmployeePayrollLock.model';
import { Employee, EmployeePolicy } from '@/domain/employee/Employee.model';
import { getEmployeeById } from '@/domain/employee/Employee.service';
import { getCurrentPrincipalEmployment } from '@/domain/employment/Employment.service';
import { cancelApprovedLeaveRequest, cancelPendingLeaveRequest } from '@/domain/leave-request/LeaveRequest.service';
import { LeaveType } from '@/domain/leave-type/LeaveType.model';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { NonWorkingType, WorkingTimeRule } from '@/domain/shift/Shift.model';
import { deleteTimesheetAdjustment } from '@/domain/timesheet-adjustment/TimesheetAdjustment.service';
import { ApproveTimesheetPaymentMutation, DeclineTimesheetPaymentMutation } from '@/domain/timesheet-payment/TimesheetPayment.model';
import { approveTimesheetPayments, declineTimesheetPayments, deleteTimesheetPayment } from '@/domain/timesheet-payment/TimesheetPayment.service';
import { deleteTimesheetRecurringAdjustment } from '@/domain/timesheet-recurring-adjustment/TimesheetRecurringAdjustment.service';
import {
    CancelTimesheetMutation,
    DayTimesheet,
    DeclineTimesheetMutation,
    Timesheet,
    TimesheetAction,
    TimesheetEmployeeMonthSearch,
    TimesheetRequestDisplayStatus,
    TimesheetsRequestStatus,
    TimesheetType,
} from '@/domain/timesheet/Timesheet.model';
import { approveTimesheet, cancelPendingTimesheet, cancelTimesheet } from '@/domain/timesheet/Timesheet.service';
import { useGetEmployeeMonthTimesheets } from '@/hooks/timesheet/Timesheet.hook';
import I18n from '@/i18n/i18n';
import {
    activeMonth,
    convertDailyTimesheetReportToTimesheetRows,
    getAppendRows,
    getAreaWithComment,
    getExcelStyles,
    getNumberOfRowByDate,
    getPrependRows,
} from '@/page/employee-profile/employee-profile-timesheet/timesheets-history/TimesheetsHistory.util';
import { TimesheetsHistoryActionsButtons } from '@/page/employee-profile/employee-profile-timesheet/timesheets-history/TimesheetsHistoryActionsButtons';
import { useEmployeeProfileId } from '@/page/employee-profile/useEmployeeProfileId';
import { AddAdjustmentDialog } from '@/page/employee-timesheet/adjustment-dialog/AddAdjustmentDialog';
import { CorrectClockInDialog } from '@/page/employee-timesheet/correct-clock-in-dialog/CorrectClockInDialog';
import { MobileTimesheetHistory } from '@/page/employee-timesheet/mobile-timesheet-history/MobileTimesheetHistory';
import { AddPaymentDialog } from '@/page/employee-timesheet/payment-dialog/AddPaymentDialog';
import { EmploymentStatusBadge } from '@/page/employment/employment-status-badge/EmploymentStatusBadge';
import { AddLeaveRequestButton } from '@/page/leave/add-leave-request-button/AddLeaveRequestButton';
import { ClockInOutStatusIconizedInfoTooltip } from '@/page/timesheet/clock-in-out-status-iconized-info-tooltip/ClockInOutStatusIconizedInfoTooltip';
import { MissingTimesheetsButton } from '@/page/timesheet/missing-timesheets-button/MissingTimesheetsButton';
import { TimesheetDialog } from '@/page/timesheet/timesheet-dialog/TimesheetDialog';
import { useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { getLabelTranslation } from '@/utils/language.util';
import { CellClassParams, ColDef, ICellRendererParams, RowClickedEvent, RowSpanParams } from 'ag-grid-community';
import { AlertCircleIcon } from 'hugeicons-react';
import { TimesheetCell } from './timesheets-history/TimesheetDisplay';
import { DeclineTimesheetsDialog } from '@/page/timesheet/decline-timesheets-dialog/DeclineTimesheetsDialog';
import { isDefined } from '@/utils/collections.util';

const canEditTimesheet = (status: TimesheetsRequestStatus, type: TimesheetType, policies: EmployeePolicy[], employeeId: number): boolean => {
    return (
        ((type === TimesheetType.MISSING ||
            type === TimesheetType.MISSING_UNPAID ||
            type === TimesheetType.MISSING_AND_UNPAID ||
            type === TimesheetType.FUTURE_MISSING ||
            type === TimesheetType.AUTOFILL) &&
            canManagePendingTimesheets(policies, employeeId)) ||
        (status === TimesheetsRequestStatus.PENDING && canManagePendingTimesheets(policies, employeeId)) ||
        (status === TimesheetsRequestStatus.APPROVED && canManageApprovedTimesheets(policies, employeeId))
    );
};

/**
 * This type is used to display the timesheet rows in the table, helps to group the timesheets by date and type
 */
export type TimesheetRow = {
    day: DayTimesheet | undefined;
    timesheet: Timesheet;
    plannedTimesheet: Timesheet;
    timesheetsGroupedByType: Timesheet[];
    timesheetsGroupedByTypeRowSpan: number;
    status: TimesheetsRequestStatus;
    nonWorkingType: NonWorkingType | undefined;
    isLastRowInGroup: boolean;
};

export const TimesheetsHistory: FC = () => {
    const params = useParams();
    const employeeId = useEmployeeProfileId();
    const year = params.year;
    const month = params.month as MONTHS;

    const { t } = useTranslation();
    const theme = useTheme();
    const agGridWrapper = useAgGridWrapper<TimesheetRow>();
    const realm = useCurrentRealm();
    const policies = useCurrentPolicies();
    const [rows, setRows] = useState<TimesheetRow[]>([]);
    const [employeeLoading, setEmployeeLoading] = useState<boolean>(true);

    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const employeeMonthTimesheetsRequestData: TimesheetEmployeeMonthSearch = {
        employeeId: employeeId,
        month: month,
        year: Number(year),
    };

    const { data = [], isLoading, error, isError, refetch: refetchMonthTimesheet } = useGetEmployeeMonthTimesheets(employeeMonthTimesheetsRequestData);

    // On this page we only need the first report
    const report = data.at(0);

    const [employee, setEmployee] = useState<Employee>();

    // Load data related to employeeId param
    useEffect(() => {
        const fetchEmployeeData = async () => {
            try {
                if (canSeeEmployeeLeaveTypes(policies, employeeId)) {
                    const leaveTypePoliciesData = await getEmployeeLeaveTypePolicies(employeeId);
                    setEmployeeLeaveTypes(leaveTypePoliciesData?.map(lt => lt.leaveType) ?? []);
                }
                const employeeData = await getEmployeeById(employeeId);
                setEmployee(employeeData);
                setEmployeeLoading(false);
            } catch (error) {
                handleError(error);
            }
        };

        if (!employee) {
            fetchEmployeeData().catch(handleError);
        }
    }, [employee, policies, employeeId]);

    // convert daily timesheet report to timesheet rows for the table
    useEffect(() => {
        if (!report) {
            return;
        }
        const rows = convertDailyTimesheetReportToTimesheetRows(report);
        setRows(rows);
    }, [report]);

    const handleEditTimesheet = (date: LocalDate) => {
        setTimesheetDialogReferenceDate(date);
        setOpenTimesheetsDialog(true);
    };

    const [openTimesheetsDialog, setOpenTimesheetsDialog] = useState<boolean>(false);
    const [timesheetDialogReferenceDate, setTimesheetDialogReferenceDate] = useState<LocalDate>(getCurrentLocalDate());
    const [mode, setMode] = useState<TimesheetAction>(TimesheetAction.CREATE);
    const [employeeLeaveTypes, setEmployeeLeaveTypes] = useState<LeaveType[]>([]);

    const [idAddPaymentDialogOpen, setIdAddPaymentDialogOpen] = useState<boolean>(false);
    const [isAdjustmentDialogOpen, setIsAdjustmentDialogOpen] = useState<boolean>(false);
    const [isCorrectClockInOpen, setIsCorrectClockInOpen] = useState<boolean>(false);
    const [paymentAdjustmentData, setPaymentAdjustmentData] = useState<Timesheet>();
    const [declineTimesheetRequest, setDeclineTimesheetRequest] = useState<DeclineTimesheetMutation>();

    if (!employeeId) {
        return;
    }

    const handleEditClick = (row: TimesheetRow) => {
        switch (row.timesheet.type) {
            case TimesheetType.AUTOFILL:
                handleEditTimesheet(row.day ? row.day.date : row.timesheet.referenceDate);
                setMode(TimesheetAction.AUTO_FILL_TIMESHEET);
                break;
            case TimesheetType.TIMESHEET:
                handleEditTimesheet(row.timesheet.referenceDate);
                setMode(TimesheetAction.EDIT);
                break;
            case TimesheetType.MISSING:
            case TimesheetType.MISSING_UNPAID:
            case TimesheetType.FUTURE_MISSING:
            case TimesheetType.MISSING_AND_UNPAID:
                handleEditTimesheet(formatToLocalDate(row.timesheet.startAt));
                setMode(TimesheetAction.EDIT);
                break;
            case TimesheetType.SHIFT_TIMESHEET:
                handleEditTimesheet(formatToLocalDate(row.timesheet.startAt));
                setMode(TimesheetAction.SHIFT_TIMESHEET);
                break;
            case TimesheetType.TIMESHEET_ADJUSTMENT:
            case TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT:
                setIsAdjustmentDialogOpen(true);
                setMode(TimesheetAction.EDIT);
                setPaymentAdjustmentData(row.timesheet);
                break;
            case TimesheetType.TIMESHEET_PAYMENT:
                setMode(TimesheetAction.EDIT);
                setIdAddPaymentDialogOpen(true);
                setPaymentAdjustmentData(row.timesheet);
                break;
            default:
                break;
        }
    };

    const handleDeleteTimesheetAdjustment = async (adjustmentId: number | undefined) => {
        if (!adjustmentId) {
            return;
        }
        try {
            await deleteTimesheetAdjustment(adjustmentId);
        } catch (error) {
            handleError(error);
        }
        await refetchMonthTimesheet();
    };

    const handleDeleteTimesheetRecurringAdjustment = async (adjustmentId: number | undefined) => {
        if (!adjustmentId) {
            return;
        }
        try {
            await deleteTimesheetRecurringAdjustment(adjustmentId);
        } catch (error) {
            handleError(error);
        }
        await refetchMonthTimesheet();
    };

    const handleDeletePayment = async (paymentId: number) => {
        try {
            await deleteTimesheetPayment(paymentId);
        } catch (error) {
            handleError(error);
        }
        await refetchMonthTimesheet();
    };

    const handleDeleteClick = async (row: TimesheetRow) => {
        try {
            switch (row.timesheet.type) {
                case TimesheetType.TIMESHEET_ADJUSTMENT:
                    await handleDeleteTimesheetAdjustment(row.timesheet.adjustmentId);
                    break;
                case TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT:
                    await handleDeleteTimesheetRecurringAdjustment(row.timesheet.recurringAdjustmentId);
                    break;
                case TimesheetType.TIMESHEET_PAYMENT: {
                    if (!row.timesheet.paymentId) {
                        return;
                    }
                    await handleDeletePayment(row.timesheet.paymentId);
                    break;
                }
                default:
                    break;
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleCorrectClockIn = () => {
        setIsCorrectClockInOpen(true);
    };

    const renderMoreButtons = (row: TimesheetRow) => {
        return renderMenuItems({
            row,
            canApproveOrDecline: isApproveDeclineEnabled(row, row.timesheetsGroupedByType, policies, employeeId),
            policies,
            employeeId,
            handleApproveClick,
            handleDeclineClick,
            handleEditClick,
            handleDeleteClick: row => {
                (async () => {
                    await handleDeleteClick(row);
                })();
            },
            handleCancelClick: row => {
                (async () => {
                    await handleCancelClick(row);
                })();
            },
            handleCorrectClockIn,
        });
    };

    const cellActionRenderer = (params: ICellRendererParams<TimesheetRow>) => {
        if (!params.data) {
            return;
        }
        const items = renderMoreButtons(params.data);
        if (!items?.length) {
            return;
        }
        return <BasicMenu items={items} />;
    };

    const handleRowClick = (params: RowClickedEvent<TimesheetRow>) => {
        if (params.event?.defaultPrevented || !params.data) {
            return;
        }
        if (!isEditDisabled(params.data, policies, employeeId)) {
            handleEditClick(params.data);
        }
    };

    const dayRenderer = ({ data: row, value }: { data?: TimesheetRow; value: string }) => {
        if (!value || !row) {
            return '';
        }

        const warnings = row.timesheet.warnings ?? [];
        return (
            <>
                {/* We don't want to manage the position, so we don't use flex container */}
                <Typography component='span'>{value}</Typography>
                {!!warnings?.length && (
                    <Tooltip
                        title={warnings.map(warning => (
                            <Typography key={warning.rule} color='common.white'>
                                {t('timesheets.warning', {
                                    context: warning.rule,
                                    limit: warning.limit,
                                })}
                            </Typography>
                        ))}
                    >
                        <Box display='inline-flex' component='span' sx={{ verticalAlign: 'middle', marginLeft: 0.5 }}>
                            <AlertCircleIcon
                                color={warnings.find(w => w.rule === WorkingTimeRule.EMPLOYEE_MISSING) ? theme.palette.error.main : theme.palette.warning.main}
                            />
                        </Box>
                    </Tooltip>
                )}
            </>
        );
    };

    const timeCellRenderer = ({ data: row, value }: { data?: TimesheetRow; value: string }) => {
        if (!row) {
            return;
        }
        return <ClockInOutStatusIconizedInfoTooltip timesheet={row.timesheet} valueToDisplay={value} realm={realm} />;
    };

    const handleApproveClick = (row: TimesheetRow) => {
        switch (row.timesheet.type) {
            case TimesheetType.TIMESHEET:
            case TimesheetType.MISSING:
            case TimesheetType.MISSING_AND_UNPAID:
            case TimesheetType.AUTOFILL:
                handleTimesheetApprove(row).catch(handleError);
                break;
            case TimesheetType.LEAVE:
            case TimesheetType.FUTURE_LEAVE:
                handleLeaveApprove(row);
                break;
            case TimesheetType.TIMESHEET_PAYMENT:
                handlePaymentApprove(row);
                break;
        }

        async function handleTimesheetApprove(row: TimesheetRow) {
            const approveRequest = {
                employeeId: employeeId,
                timesheetIds: row.timesheetsGroupedByType?.map(timesheet => {
                    return timesheet.id;
                }),
            };
            try {
                await approveTimesheet(approveRequest);
            } catch (error) {
                handleError(error);
            } finally {
                await refetchMonthTimesheet();
            }
        }

        // This is actually KO because we don't have the approveLeaveRequest variable
        function handleLeaveApprove(row: TimesheetRow) {
            console.error(row);
        }

        async function handlePaymentApprove(row: TimesheetRow) {
            if (!row.timesheet.paymentId) {
                return;
            }
            const paymentRequest: ApproveTimesheetPaymentMutation = {
                paymentIds: [row.timesheet.paymentId],
                paymentDueDate: getCurrentLocalDate(),
            };
            try {
                await approveTimesheetPayments(paymentRequest);
            } catch (error) {
                handleError(error);
            } finally {
                await refetchMonthTimesheet();
            }
        }
    };

    const handleCancelClick = async (row: TimesheetRow) => {
        try {
            switch (row.timesheet.type) {
                case TimesheetType.SHIFT_TIMESHEET:
                    break; //TODO: RP-2846 remove this when we stop having the cancel option
                case TimesheetType.TIMESHEET:
                case TimesheetType.MISSING:
                case TimesheetType.MISSING_AND_UNPAID:
                case TimesheetType.AUTOFILL: {
                    const cancelRequest: CancelTimesheetMutation = {
                        employeeId: employeeId,
                        timesheetIds: row.timesheetsGroupedByType?.map(timesheet => {
                            return timesheet.id;
                        }),
                    };

                    try {
                        if (row.status === TimesheetsRequestStatus.PENDING) {
                            await cancelPendingTimesheet(cancelRequest);
                        } else {
                            await cancelTimesheet(cancelRequest);
                        }
                    } catch (error) {
                        handleError(error);
                    } finally {
                        await refetchMonthTimesheet();
                    }
                    break;
                }
                default: {
                    const leaveRequestId = row.timesheet.leaveTimesheet?.id;
                    if (!leaveRequestId) {
                        return;
                    }

                    try {
                        if (row.status === TimesheetsRequestStatus.APPROVED) {
                            await cancelApprovedLeaveRequest(leaveRequestId);
                        } else if (row.status === TimesheetsRequestStatus.PENDING) {
                            await cancelPendingLeaveRequest(leaveRequestId);
                        }
                    } catch (error) {
                        handleError(error);
                    } finally {
                        await refetchMonthTimesheet();
                    }
                }
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleDeclineClick = (row: TimesheetRow) => {
        switch (row.timesheet.type) {
            case TimesheetType.TIMESHEET:
                handleTimesheetDecline(row);
                break;
            case TimesheetType.LEAVE:
            case TimesheetType.FUTURE_LEAVE:
                handleLeaveDecline(row);
                break;
            case TimesheetType.TIMESHEET_PAYMENT:
                handlePaymentDecline(row);
                break;
        }

        async function handleTimesheetDecline(row: TimesheetRow) {
            const declineRequest: DeclineTimesheetMutation = {
                employeeId: employeeId,
                timesheetIds: row.timesheetsGroupedByType?.map(timesheet => {
                    return timesheet.id;
                }),
            };
            setDeclineTimesheetRequest(declineRequest);
        }

        // TODO This is KO
        function handleLeaveDecline(row: TimesheetRow) {
            console.error(row);
        }

        async function handlePaymentDecline(row: TimesheetRow) {
            if (!row.timesheet.paymentId) {
                return;
            }
            const declineTimesheetPaymentsRequest: DeclineTimesheetPaymentMutation = {
                paymentIds: [row.timesheet.paymentId],
            };
            try {
                await declineTimesheetPayments(declineTimesheetPaymentsRequest);
            } catch (error) {
                handleError(error);
            } finally {
                await refetchMonthTimesheet();
            }
        }
    };

    const handleBatchApproveTimesheets = async () => {
        const approvedIds =
            selectedRows
                ?.filter(t => canApproveOrSelectTimesheets(t.timesheetsGroupedByType))
                .flatMap(i => i.timesheetsGroupedByType)
                .map(i => i.id)
                .filter(i => !!i) ?? [];
        const approveTimesheets = {
            employeeId: employeeId,
            timesheetIds: approvedIds,
        };

        try {
            await approveTimesheet(approveTimesheets);
        } catch (error) {
            handleError(error);
        } finally {
            await refetchMonthTimesheet();
            agGridWrapper.gridRef.current?.api?.deselectAll();
        }
    };

    const handleBatchDeclineTimesheets = () => {
        const declinedIds =
            selectedRows
                ?.flatMap(i => i.timesheetsGroupedByType)
                .map(i => i.id)
                .filter(i => !!i) ?? [];
        const declineRequest: DeclineTimesheetMutation = {
            employeeId: employeeId,
            timesheetIds: declinedIds,
        };
        setDeclineTimesheetRequest(declineRequest);
    };

    if (!employee) {
        return <Spinner />;
    }

    const selectedRows = agGridWrapper?.selectedRows;

    const isPublicHoliday = (row: TimesheetRow) => {
        return row.day?.timesheets?.some(timesheet => timesheet.type === TimesheetType.PUBLIC_HOLIDAY);
    };
    const canApproveOrSelectTimesheets = (timesheets: Timesheet[]): boolean => !timesheets.some(timesheet => !timesheet.endAt);

    const showPlanningColumn = hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.PLANNING) && rows?.some(row => row?.plannedTimesheet);

    const formatHours = (value: number | undefined) => {
        if (!value) {
            return '';
        }
        return value ? formatDurationInHours(value) : '';
    };

    const getTotalLeaveCount = (row: TimesheetRow): number => {
        if (!row?.day?.timesheets?.length) {
            return 0;
        }
        //in case we have a public holiday
        if (row && isPublicHoliday(row)) {
            return row.day?.publicHolidayCount;
        }
        return row.day?.leaveCount + row.day?.compensationCount + row.day?.unpaidLeaveCount + row.day?.futureUnpaidLeaveCount + row.day.futureLeaveCount;
    };

    const getContractCount = (row: TimesheetRow): number => {
        if (!row?.day?.timesheets?.length) {
            return 0;
        }
        if (isPublicHoliday(row)) {
            return row.day?.publicHolidayCount;
        }
        return row.day?.contractCount;
    };

    const getCompensationCount = (row: TimesheetRow) => {
        if (!row?.day?.timesheets?.length) {
            return 0;
        }
        return row?.day.compensationCount + row?.day.futureCompensationCount ? -(row?.day.compensationCount + row?.day.futureCompensationCount) : 0;
    };

    const isLastSpanningCell = (params: CellClassParams<TimesheetRow>) => !params.data?.isLastRowInGroup && getNumberOfRowByDate(params) > 1;
    const columnDefs: ColDef<TimesheetRow>[] = [
        {
            headerName: t('timesheets.history_table_header.day'),
            // We need to set a colId, to keep the column in the export
            colId: 'day',
            valueGetter: ({ data }) => (data?.day?.date ? formatDate(data?.day?.date, 'E') : ''),
            cellRenderer: dayRenderer,
            width: 75, // we need a bigger width than what it appears because of the display of the error icon
            rowSpan: getNumberOfRowByDate,
            cellClassRules: {
                'last-spanning-cell': isLastSpanningCell,
            },
            cellClass: ['span-cell'],
            minWidth: 70, //value used for width on Excel export
        },
        {
            headerName: t('timesheets.history_table_header.date'),
            field: 'day.date',
            type: 'date',
            rowSpan: getNumberOfRowByDate,
            cellClassRules: {
                'last-spanning-cell': isLastSpanningCell,
            },
            width: 100,
            cellClass: ['span-cell', 'dateCH'],
            minWidth: 110, //value used for width on Excel export
        },
        {
            field: 'timesheet.type',
            headerName: t('timesheets.history_table_header.attendance'),
            valueGetter: ({ data }) =>
                data
                    ? TimesheetCell({
                          item: data,
                          userLeaveTypes: employeeLeaveTypes,
                          timesheetSetting: data?.timesheet?.timesheetSetting,
                          isPlannedTimesheetCell: false,
                      })
                    : '',
            cellRenderer: timeCellRenderer,
            width: 180,
            minWidth: 130, //value used for width on Excel export
            cellClass: ['span-cell', 'display-flex', 'full-width'],
        },
        {
            headerName: t('timesheets.history_table_header.planning'),
            valueGetter: ({ data }) =>
                data
                    ? TimesheetCell({
                          item: data,
                          userLeaveTypes: employeeLeaveTypes,
                          timesheetSetting: data?.timesheet?.timesheetSetting,
                          isPlannedTimesheetCell: true,
                      })
                    : '',
            hide: !showPlanningColumn,
            cellClass: ['span-cell', 'display-flex', 'full-width'],
            flex: 1,
        },
        {
            field: 'timesheet.comment',
            headerName: t('timesheets.history_table_header.comment'),
            valueGetter: ({ data }) => (data ? getAreaWithComment(data) : ''),
            flex: 1,
            maxWidth: 2000,
            tooltipValueGetter: ({ data }) => (data ? getAreaWithComment(data) : ''),
            minWidth: 120, //value used for width on Excel export
            cellClass: 'span-cell',
        },
        {
            field: 'day.workedCount',
            headerName: t('timesheets.history_table_header.worked_count'),
            // Workaround to patch issue with cellDataType number inferred on Excel export
            // if all values are numbers, the column will be formatted as number in Excel but we want to keep it as text (ex 8h30)
            valueGetter: ({ data }) => formatHours(data?.day?.workedCount),
            rowSpan: getNumberOfRowByDate,
            cellClassRules: {
                'last-spanning-cell': isLastSpanningCell,
            },
            width: 100,
            minWidth: 110, //value used for width on Excel export
            cellClass: ['span-cell'],
        },
        {
            headerName: t('timesheets.history_table_header.leave_count'),
            colId: '_totalLeaveCount',
            // Workaround to patch issue with cellDataType number inferred on Excel export
            // if all values are numbers, the column will be formatted as number in Excel but we want to keep it as text (ex 8h30)
            valueGetter: ({ data }) => {
                const totalLeaveCount = data ? getTotalLeaveCount(data) : 0;
                return formatHours(totalLeaveCount);
            },
            rowSpan: getNumberOfRowByDate,
            cellClassRules: {
                'last-spanning-cell': isLastSpanningCell,
            },
            width: 112,
            minWidth: 110, //value used for width on Excel export
            cellClass: ['span-cell'],
        },
        {
            headerName: t('timesheets.history_table_header.contract_count'),
            colId: 'contractCount',
            // Workaround to patch issue with cellDataType number inferred on Excel export
            // if all values are numbers, the column will be formatted as number in Excel but we want to keep it as text (ex 8h30)
            valueGetter: ({ data }) => {
                const contractCount = data ? getContractCount(data) : 0;
                return formatHours(contractCount);
            },
            rowSpan: getNumberOfRowByDate,
            cellClassRules: {
                'last-spanning-cell': isLastSpanningCell,
            },
            width: 112,
            minWidth: 110, //value used for width on Excel export
            cellClass: ['span-cell'],
        },
        {
            headerName: t('timesheets.history_table_header.difference'),
            colId: 'difference',
            // Workaround to patch issue with cellDataType number inferred on Excel export
            // if all values are numbers, the column will be formatted as number in Excel but we want to keep it as text (ex 8h30)
            valueGetter: ({ data }) => formatHours(data?.day?.difference),
            rowSpan: getNumberOfRowByDate,
            cellClassRules: {
                'last-spanning-cell': isLastSpanningCell,
            },
            width: 112,
            minWidth: 110, //value used for width on Excel export
            cellClass: ['span-cell'],
        },
        {
            headerName: t('timesheets.history_table_header.bonus_count'),
            colId: 'bonusCount',
            rowSpan: getNumberOfRowByDate,
            cellClassRules: {
                'last-spanning-cell': isLastSpanningCell,
            },
            // Workaround to patch issue with cellDataType number inferred on Excel export
            // if all values are numbers, the column will be formatted as number in Excel but we want to keep it as text (ex 8h30)
            valueGetter: ({ data }) => formatHours(data?.day?.bonusCount),
            hide: rows?.every(row => !row?.day?.bonusCount),

            width: 100,
            minWidth: 110, //value used for width on Excel export
            cellClass: ['span-cell'],
        },
        {
            headerName: t('timesheets.history_table_header.compensation'),
            colId: 'compensationCount',
            rowSpan: getNumberOfRowByDate,
            cellClassRules: {
                'last-spanning-cell': isLastSpanningCell,
            },
            // Workaround to patch issue with cellDataType number inferred on Excel export
            // if all values are numbers, the column will be formatted as number in Excel but we want to keep it as text (ex 8h30)
            valueGetter: ({ data }) => {
                const total = data ? getCompensationCount(data) : 0;
                return formatHours(total);
            },
            hide: rows?.every(row => !row?.day?.compensationCount && !row?.day?.futureCompensationCount),
            width: 100,
            minWidth: 110, //value used for width on Excel export
            cellClass: ['span-cell'],
        },
        {
            headerName: t('timesheets.history_table_header.balance'),
            colId: 'cumulatedBalance',
            // Workaround to patch issue with cellDataType number inferred on Excel export
            // if all values are numbers, the column will be formatted as number in Excel but we want to keep it as text (ex 8h30)
            valueGetter: ({ data }) => formatHours(data?.day?.cumulatedBalance),
            rowSpan: getNumberOfRowByDate,
            cellClassRules: {
                'last-spanning-cell': isLastSpanningCell,
            },
            width: 112,
            minWidth: 110, //value used for width on Excel export
            cellClass: ['span-cell'],
        },
        {
            field: 'status',
            headerName: t('timesheets.history_table_header.status'),
            cellRenderer: (params: ICellRendererParams<TimesheetRow>) => (params?.data?.day || params?.data?.status ? GetStatusChips(params) : undefined),
            rowSpan: (params: RowSpanParams<TimesheetRow>) => (params.data ? params.data.timesheetsGroupedByTypeRowSpan : 1),
            cellClass: ['span-cell', 'display-flex', 'full-width'],
            maxWidth: 125,
        },
        {
            type: 'actionMenu',
            cellRenderer: cellActionRenderer,
            rowSpan: (params: RowSpanParams<TimesheetRow>) => (params.data ? params.data.timesheetsGroupedByTypeRowSpan : 1),
            cellClass: ['span-cell', 'full-width'],
        },
    ];

    const removePlanningColumn = () => {
        const columnIndexToRemove = columnDefs.findIndex(columnDef => columnDef.colId === 'timesheets.planning');
        if (columnIndexToRemove !== -1) {
            columnDefs.splice(columnIndexToRemove, 1);
        }
    };

    if (!hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.PLANNING)) {
        removePlanningColumn();
    }

    const handleExportClick = () => {
        if (!report) {
            return;
        }

        const columnKeys = columnDefs
            // If column has hide property, we don't want to export it, avoid to export empty columns in excel file
            // Compensation count or bonus count could be empty
            .filter(column => !column.hide)
            .map(column => column.colId ?? column.field)
            // we don't want to export the status column
            .filter(c => c !== 'status')
            .filter(isDefined);

        const selectedDate = formatToLocalDate(new Date(Number(year), activeMonth(month)));
        const startDate = getStartOfMonth(selectedDate);
        const endDate = getEndOfMonth(selectedDate);

        const fileName = t('timesheets.export_file_name', {
            month: t(getMonthTranslationKey(month)),
            displayName: employee?.displayName,
            date: getCurrentLocalDate(),
        });

        agGridWrapper.gridRef.current?.api?.exportDataAsExcel({
            // Keeps control over the exported columns
            columnKeys: columnKeys,
            fileName: fileName,
            prependContent: getPrependRows({
                employee,
                startDate: startDate,
                endDate: endDate,
                totalWorkedTime: report.totalWorkedCount,
                totalLeave: report.totalLeaveCount + report.totalUnpaidLeaveCount + report.totalPublicHolidayCount,
                totalContract: report.totalContractCount + report.totalPublicHolidayCount,
                totalPayment: report.totalPaymentCount,
                totalAdjustment: report.totalAdjustmentCount,
                overtimeBalance: report.totalDifferenceCumulated,
                columnsCount: columnKeys.length,
            }),
            appendContent: getAppendRows({
                columnsCount: columnKeys.length,
            }),
        });
    };

    if (isLoading || employeeLoading) {
        return <Spinner />;
    }

    const handlePaymentDialogSave = async () => {
        setIdAddPaymentDialogOpen(false);
        setMode(TimesheetAction.CREATE);
        await refetchMonthTimesheet();
    };

    const handleAdjustmentDialogSave = async () => {
        setIsAdjustmentDialogOpen(false);
        setMode(TimesheetAction.CREATE);
        await refetchMonthTimesheet();
    };

    const handleCorrectClockInDialogSave = async () => {
        setIsCorrectClockInOpen(false);
        setMode(TimesheetAction.CREATE);
        await refetchMonthTimesheet();
    };

    const handleRefresh = async () => {
        await refetchMonthTimesheet();
    };

    const isNonWorkingDay = (row?: TimesheetRow) => {
        return row?.nonWorkingType === NonWorkingType.DAY_OFF || row?.nonWorkingType === NonWorkingType.PUBLIC_HOLIDAY;
    };

    const handleOnCancelDecline = () => {
        setDeclineTimesheetRequest(undefined);
    };

    const onSuccessDecline = async () => {
        setDeclineTimesheetRequest(undefined);
        await refetchMonthTimesheet();
        agGridWrapper.gridRef.current?.api?.deselectAll();
    };

    const employment = getCurrentPrincipalEmployment(employee);

    return (
        <StateHandler error={error} isError={isError} isLoading={isLoading || employeeLoading}>
            {report && !isMobile && (
                <Stack direction='column' gap={2} flex={1}>
                    <Stack direction='column' spacing={2}>
                        <Stack direction='row' alignItems='center' spacing={2}>
                            <EmployeeAvatarWithDetails employee={employee} avatarProps={{ size: 'large' }}>
                                <Stack gap={0.5}>
                                    <Typography variant='body2'>{getLabelTranslation(employment?.job?.name)}</Typography>
                                    <EmploymentStatusBadge employmentStatus={employee?.employmentStatus} />
                                </Stack>
                            </EmployeeAvatarWithDetails>
                            <Box component={Paper} padding={2}>
                                {!!report && (
                                    <Stack direction='row' alignItems='center' spacing={4}>
                                        <HistoryResultsInfo
                                            title={t('timesheets.history_page_header.worked_hours')}
                                            content={formatDurationInHours(report.totalWorkedCount)}
                                        />
                                        {report.totalBonusCount !== 0 && (
                                            <HistoryResultsInfo
                                                title={t('timesheets.history_page_header.total_bonus_count')}
                                                content={formatDurationInHours(report.totalBonusCount)}
                                            />
                                        )}
                                        <HistoryResultsInfo
                                            title={t('timesheets.history_page_header.paid_leaves')}
                                            content={formatDurationInHours(
                                                report.totalLeaveCount + report.totalFutureLeaveCount + report.totalPublicHolidayCount,
                                            )}
                                        />
                                        <HistoryResultsInfo
                                            title={t('timesheets.history_page_header.contract')}
                                            content={formatDurationInHours(report.totalContractCount + report.totalPublicHolidayCount)}
                                        />
                                        {report.totalPaymentCount !== 0 && (
                                            <HistoryResultsInfo
                                                title={t('timesheets.history_page_header.payments')}
                                                content={`-${formatDurationInHours(report.totalPaymentCount)}`}
                                            />
                                        )}
                                        {report.totalAdjustmentCount !== 0 && (
                                            <HistoryResultsInfo
                                                title={t('timesheets.history_page_header.adjustments')}
                                                content={formatDurationInHours(report.totalAdjustmentCount)}
                                            />
                                        )}
                                    </Stack>
                                )}
                            </Box>
                        </Stack>
                    </Stack>
                    <Stack direction={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                        <Stack direction='row' gap={1}>
                            <MissingTimesheetsButton employee={employee} dailyTimesheetReport={report} onSuccess={handleRefresh} />

                            <AddLeaveRequestButton employee={employee} onSuccess={handleRefresh} />

                            <TimesheetsHistoryActionsButtons employee={employee} onExportClick={handleExportClick} onSuccess={handleRefresh} />
                        </Stack>
                    </Stack>

                    {!!rows?.length && (
                        <AgGridWrapper<TimesheetRow>
                            initRef={agGridWrapper.setGridRef}
                            rowData={rows}
                            defaultColDef={{ sortable: false, resizable: false, autoHeight: false }}
                            columnDefs={columnDefs.map(colDef => ({
                                ...colDef,
                                cellClassRules: {
                                    ...colDef.cellClassRules,
                                    // Use for excel export
                                    'non-working-day': ({ data }) => isNonWorkingDay(data),
                                },
                            }))}
                            rowSelection={
                                canApproveRejectTimesheets(policies, employeeId)
                                    ? {
                                          mode: 'multiRow',

                                          hideDisabledCheckboxes: true,
                                          // only timesheet with type TIMESHEET can be selected
                                          // when it's grouped, only the first row of the group is selectable
                                          isRowSelectable: n => !!n.data?.timesheet.id && !!n.data.timesheetsGroupedByTypeRowSpan,
                                      }
                                    : undefined
                            }
                            selectionColumnDef={{
                                cellClass: ({ data }: CellClassParams<TimesheetRow>) =>
                                    data?.isLastRowInGroup ? ['last-spanning-cell', 'span-selection-cell'] : ['span-selection-cell'],
                            }}
                            defaultExcelExportParams={{
                                columnWidth: ({ column }) => column?.getMinWidth() ?? 100,
                                fontSize: 9,
                                pageSetup: {
                                    orientation: 'Landscape',
                                    pageSize: 'A4',
                                },
                                margins: {
                                    top: 0.5,
                                    right: 0.5,
                                    bottom: 0.5,
                                    left: 0.5,
                                    header: 0.25,
                                    footer: 0.25,
                                },

                                rowHeight: 27,
                            }}
                            excelStyles={getExcelStyles(theme)}
                            onRowClicked={handleRowClick}
                            getRowClass={params => (isNonWorkingDay(params.data) ? 'non-working-row' : undefined)}
                            toolbarActions={
                                <Stack direction='row' gap={2}>
                                    <Button onClick={handleBatchApproveTimesheets}>{t('timesheets.approve_timesheets')}</Button>
                                    <Button color='error' onClick={handleBatchDeclineTimesheets}>
                                        {t('timesheets.decline_timesheets')}
                                    </Button>
                                </Stack>
                            }
                            disableAutoSize
                            loading={false}
                        />
                    )}
                </Stack>
            )}

            {report && isMobile && year && (
                <Stack component={Paper}>
                    <MobileTimesheetHistory
                        rows={rows}
                        year={year}
                        month={month}
                        dailyTimesheetReport={report}
                        addTimesheet={() => {
                            setMode(TimesheetAction.CREATE);
                            setOpenTimesheetsDialog(true);
                        }}
                        onEditClicked={handleEditClick}
                        menuRenderer={data => renderMoreButtons(data)}
                        leaveTypes={employeeLeaveTypes}
                        employee={employee}
                    />
                </Stack>
            )}

            {openTimesheetsDialog && (
                <TimesheetDialog
                    defaultReferenceDate={timesheetDialogReferenceDate}
                    mode={mode}
                    open={openTimesheetsDialog}
                    employee={employee}
                    onClose={() => {
                        setOpenTimesheetsDialog(false);
                        setTimesheetDialogReferenceDate(getCurrentLocalDate());
                    }}
                    onSave={async () => {
                        await refetchMonthTimesheet();
                        setTimesheetDialogReferenceDate(getCurrentLocalDate());
                        setOpenTimesheetsDialog(false);
                    }}
                />
            )}

            {idAddPaymentDialogOpen && (
                <AddPaymentDialog
                    open={idAddPaymentDialogOpen}
                    onClose={() => {
                        setIdAddPaymentDialogOpen(false);
                        setIsAdjustmentDialogOpen(false);
                        setMode(TimesheetAction.CREATE);
                    }}
                    paymentData={paymentAdjustmentData}
                    onSave={handlePaymentDialogSave}
                    employeeId={employeeId}
                />
            )}
            {isAdjustmentDialogOpen && (
                <AddAdjustmentDialog
                    open={isAdjustmentDialogOpen}
                    onClose={() => {
                        setIsAdjustmentDialogOpen(false);
                        setMode(TimesheetAction.CREATE);
                    }}
                    adjustmentId={paymentAdjustmentData?.adjustmentId}
                    recurringAdjustmentId={paymentAdjustmentData?.recurringAdjustmentId}
                    onSave={handleAdjustmentDialogSave}
                    employeeIds={[employeeId]}
                />
            )}
            {isCorrectClockInOpen && (
                <CorrectClockInDialog
                    open={isCorrectClockInOpen}
                    referenceDate={getCurrentLocalDate()}
                    employeeId={employeeId}
                    onClose={() => setIsCorrectClockInOpen(false)}
                    onSave={handleCorrectClockInDialogSave}
                />
            )}
            {!!declineTimesheetRequest?.timesheetIds && (
                <DeclineTimesheetsDialog
                    timesheetIds={declineTimesheetRequest.timesheetIds}
                    employeeId={declineTimesheetRequest.employeeId}
                    onCancel={handleOnCancelDecline}
                    onSuccess={onSuccessDecline}
                />
            )}
        </StateHandler>
    );
};

const HistoryResultsInfo: FC<{
    title: string;
    content: string;
}> = ({ title, content }) => {
    return (
        <Stack>
            <Typography variant='body2'>{title}</Typography>
            <Typography color='textPrimary' variant={'h2'}>
                {content}
            </Typography>
        </Stack>
    );
};

const GetStatusChips = (params: ICellRendererParams<TimesheetRow>): ReactElement => {
    const getTimesheetStatus = (timesheetRow: TimesheetRow): string => {
        let status: TimesheetsRequestStatus | TimesheetRequestDisplayStatus = timesheetRow.status ?? timesheetRow.timesheet.status;
        if (timesheetRow.timesheet.type === TimesheetType.AUTOFILL) {
            status = TimesheetRequestDisplayStatus.AUTO_FILLED;
        }
        if (!status && (timesheetRow.timesheet.type === TimesheetType.MISSING || timesheetRow.timesheet.type === TimesheetType.MISSING_AND_UNPAID)) {
            status = TimesheetRequestDisplayStatus.MISSING;
        }
        if (timesheetRow.timesheet.type === TimesheetType.SHIFT_TIMESHEET) {
            status = TimesheetRequestDisplayStatus.SHIFT_TIMESHEET;
        }
        if (timesheetRow?.timesheet?.lock) {
            status = TimesheetRequestDisplayStatus.LOCKED;
        }
        return status;
    };

    if (!params.data) {
        return <></>;
    }

    return (
        <Tooltip title={StatusChipsTooltipTitle(params.data.timesheet)}>
            <RequestStatusChip status={getTimesheetStatus(params.data)} />
        </Tooltip>
    );
};

const StatusChipsTooltipTitle = (timesheet: Timesheet) => {
    const { t } = useTranslation();

    const generateApprovedStatusText = (timesheet: Timesheet): string | undefined => {
        const { statusUpdatedBy, statusUpdatedAt } = timesheet;
        const formattedDate = formatInDefaultDate(statusUpdatedAt);
        const shouldShowApprovedStatusText = timesheet.status === TimesheetsRequestStatus.APPROVED && statusUpdatedBy && statusUpdatedAt;

        return shouldShowApprovedStatusText
            ? t('timesheets.status_chip_approved_by_on', {
                  name: statusUpdatedBy?.displayName,
                  date: formattedDate,
              })
            : undefined;
    };

    const generateApprovedPaymentStatusText = (timesheet: Timesheet): string | undefined => {
        const { paymentDueDate } = timesheet;

        if (!paymentDueDate) {
            return undefined;
        }

        return t('timesheets.status_chip_payments_due_date', { dueDate: formatInDefaultDate(paymentDueDate) });
    };

    const isPaymentTimesheetApproved = timesheet.type === TimesheetType.TIMESHEET_PAYMENT && timesheet.status === TimesheetsRequestStatus.APPROVED;

    const approvedStatusText = isPaymentTimesheetApproved ? generateApprovedPaymentStatusText(timesheet) : generateApprovedStatusText(timesheet);

    const showLockInformation = timesheet?.lock?.lockedAt && timesheet.lock.createdBy;

    if (!showLockInformation && !approvedStatusText) {
        return undefined;
    }
    return showLockInformation ? <StatusChipTooltipTitleLocked lock={timesheet.lock} /> : approvedStatusText;
};

export const StatusChipTooltipTitleLocked: FC<{ lock?: EmployeePayrollLock }> = ({ lock }) => {
    const { t } = useTranslation();
    if (!lock) {
        return undefined;
    }
    return (
        <Typography variant='body2' color='common.white'>
            {t('timesheets.locked_by', {
                lockedBy: lock.createdBy?.displayName,
                updatedAt: lock.updatedAt,
            })}
            <br />
            {t('timesheets.locked_until', { lockedUntil: lock.lockedAt })}
            {lock.comment && <Stack>{t('timesheets.Locked_comment', { comment: lock.comment })}</Stack>}
        </Typography>
    );
};

type renderMoreButtonsProps = {
    row: TimesheetRow;
    canApproveOrDecline: boolean;
    policies: EmployeePolicy[];
    employeeId: number;
    handleApproveClick: (row: TimesheetRow) => void;
    handleDeclineClick: (row: TimesheetRow) => void;
    handleEditClick: (row: TimesheetRow) => void;
    handleDeleteClick: (row: TimesheetRow) => void;
    handleCancelClick: (row: TimesheetRow) => void;
    handleCorrectClockIn: () => void;
};

const renderMenuItems = (props: renderMoreButtonsProps): BasicMenuItem[] => {
    const {
        row,
        canApproveOrDecline,
        policies,
        employeeId,
        handleApproveClick,
        handleDeclineClick,
        handleEditClick,
        handleDeleteClick,
        handleCancelClick,
        handleCorrectClockIn,
    } = props;
    if (
        row.timesheet.type === TimesheetType.LEAVE ||
        row.timesheet.type === TimesheetType.FUTURE_LEAVE ||
        row.timesheet.type === TimesheetType.COMPENSATION ||
        row.timesheet.type === TimesheetType.FUTURE_COMPENSATION
    ) {
        //TODO: RP-2685 to implement this functionality
        return [];
    }

    const menuItems: BasicMenuItem[] = [];
    if (canApproveOrDecline) {
        menuItems.push(
            {
                title: I18n.t('general.approve'),
                onClick: () => handleApproveClick(row),
            },
            {
                title: I18n.t('general.decline'),
                onClick: () => handleDeclineClick(row),
            },
        );
    }
    if (!isEditDisabled(row, policies, employeeId)) {
        menuItems.push({
            title: I18n.t('general.edit'),
            onClick: () => handleEditClick(row),
        });
    }
    if (isDeleteEnabled(row)) {
        menuItems.push({
            title: I18n.t('general.delete'),
            onClick: () => handleDeleteClick(row),
        });
    }
    if (!isCancelDisabled(row, policies, employeeId)) {
        menuItems.push({
            title: I18n.t('general.cancel'),
            onClick: () => handleCancelClick(row),
        });
    }
    if (isMissingClockIn(row, policies, employeeId)) {
        menuItems.push({
            title: I18n.t('timesheets.correct_clock_in'),
            onClick: () => handleCorrectClockIn(),
        });
    }

    return menuItems;
};

const isMissingClockIn = (row: TimesheetRow, policies: EmployeePolicy[], employeeId: number) => {
    //only allow to correct the clock in IF there is no timesheet for the day and the employee has the permission to manage pending timesheets
    //ALSO, this is only available for today's date
    if (row.day?.date !== getCurrentLocalDate()) {
        return false;
    }
    return (
        canManagePendingTimesheets(policies, employeeId) &&
        row.timesheetsGroupedByType?.every(timesheet => {
            return timesheet.endAt;
        })
    );
};

const isEditDisabled = (row: TimesheetRow, policies: EmployeePolicy[], employeeId: number) => {
    switch (row.timesheet.type) {
        case TimesheetType.SHIFT_TIMESHEET:
            return false;
        case TimesheetType.TIMESHEET:
        case TimesheetType.MISSING:
        case TimesheetType.MISSING_UNPAID:
        case TimesheetType.MISSING_AND_UNPAID:
        case TimesheetType.FUTURE_MISSING:
        case TimesheetType.AUTOFILL:
            return row.timesheetsGroupedByType?.some(timesheet => !canEditTimesheet(timesheet.status, timesheet.type, policies, employeeId));
        case TimesheetType.TIMESHEET_ADJUSTMENT:
        case TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT:
            return !canManageTimesheetAdjustments(policies, employeeId);
        case TimesheetType.TIMESHEET_PAYMENT:
            return !canManageTimesheetPayments(policies, employeeId);
        default:
            return true;
    }
};

const isCancelDisabled = (row: TimesheetRow, policies: EmployeePolicy[], employeeId: number) => {
    switch (row.timesheet.type) {
        case TimesheetType.SHIFT_TIMESHEET:
            return false;
        case TimesheetType.TIMESHEET:
        case TimesheetType.AUTOFILL:
        case TimesheetType.MISSING_UNPAID:
            return row.timesheetsGroupedByType?.some(timesheet => !canEditTimesheet(timesheet.status, timesheet.type, policies, employeeId));
        default:
            return true;
    }
};

const isApproveDeclineEnabled = (item: TimesheetRow, timesheetsGroupedByTypeTimesheet: Timesheet[], policies: EmployeePolicy[], employeeId: number) => {
    switch (item.timesheet.type) {
        case TimesheetType.TIMESHEET:
        case TimesheetType.MISSING:
        case TimesheetType.AUTOFILL:
            return (
                timesheetsGroupedByTypeTimesheet?.every(timesheet => {
                    return timesheet.status === TimesheetsRequestStatus.PENDING;
                }) &&
                !timesheetsGroupedByTypeTimesheet?.some(timesheet => {
                    return !timesheet.endAt;
                }) &&
                canApproveRejectTimesheets(policies, employeeId)
            );
        case TimesheetType.LEAVE:
        case TimesheetType.FUTURE_LEAVE: {
            const isPendingLeave = item.timesheet.status === TimesheetsRequestStatus.PENDING;
            const canApproveRejectLeaves = canApproveRejectLeaveRequests(policies, employeeId);
            return canApproveRejectLeaves && isPendingLeave;
        }
        case TimesheetType.UNPAID_LEAVE:
        case TimesheetType.FUTURE_UNPAID_LEAVE:
        case TimesheetType.COMPENSATION:
        case TimesheetType.FUTURE_COMPENSATION:
            return true;
        case TimesheetType.TIMESHEET_ADJUSTMENT:
        case TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT:
            return item.timesheet.status === TimesheetsRequestStatus.PENDING && canManageTimesheetAdjustments(policies, employeeId);
        case TimesheetType.TIMESHEET_PAYMENT:
            return item.timesheet.status === TimesheetsRequestStatus.PENDING && canManageTimesheetPayments(policies, employeeId);
        default:
            return false;
    }
};

const isDeleteEnabled = (row: TimesheetRow) => {
    switch (row.timesheet.type) {
        case TimesheetType.TIMESHEET_ADJUSTMENT:
        case TimesheetType.TIMESHEET_RECURRING_ADJUSTMENT:
        case TimesheetType.TIMESHEET_PAYMENT:
            return true;
        default:
            return false;
    }
};
