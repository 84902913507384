// Spring security doesn't allow to send the Authorization header in public routes

import { AUTH_API_BASE_PATH, authApi } from '@/api/auth/AuthApi';
import { client } from '@/api/common';
import { getAppConfig } from '@/config/config';
import { store } from '@/stores/store';
import { sendErrorInBugsnag } from '@/utils/api.util';
import { clearUserAuthentication, getUserAuthentication, setUserAuthentication } from '@/utils/auth.util';
import { isPastDate, subMinutes } from '@/utils/datetime.util';
import { AxiosError, InternalAxiosRequestConfig } from 'axios';

// see: https://github.com/spring-projects/spring-security/issues/12599
const UNSECURE_ROUTES = [
    '/v1/realms/',
    '/v1/realms/search',
    '/v1/surveys/auth',
    '/v1/employees/login-method/search',
    '/v1/auth/sign-in',
    '/v1/auth/confirm-new-password',
    '/v1/auth/new-password',
    '/v1/auth/reset-password',
    '/v1/auth/refresh-token',
    '/v1/auth/oauth2/authorize',
    '/v1/auth/oauth2/token',
];

const onRequest = async (requestConfig: InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig> => {
    try {
        // default content type header to "application/json"
        if (!requestConfig.headers['Content-Type']) {
            requestConfig.headers['Content-Type'] = getAppConfig().MIME_TYPES.JSON;
        }

        // we don't want to trigger an other refresh token request while refreshing the token
        if (requestConfig.url !== `${AUTH_API_BASE_PATH}/refresh-token`) {
            await checkAndRefreshToken();
        }

        const authenticatedUser = getUserAuthentication();
        if (authenticatedUser && requestConfig.url && isSecureRoute(requestConfig.url)) {
            requestConfig.headers['Authorization'] = `Bearer ${authenticatedUser.idToken}`;
        }
    } catch (error) {
        // if the user is not authenticated don't block the client call, just log the error
        console.error('Authentication error => ', error);
    }

    return requestConfig;
};

client.interceptors.request.use(onRequest);

client.interceptors.response.use(
    response => response,
    (error: AxiosError) => {
        console.error(error);

        if (error.response?.status === 401) {
            // Clear the user authentication and redirect to login page
            clearUserAuthentication();
            redirect.toLogin();
            return;
        }

        // We don't want to send the error to Bugsnag if the request
        // was canceled
        // or if there is a network error
        // or if the response status is 403
        const mustBeSendInBugsnag = error?.code !== AxiosError.ERR_CANCELED && error?.code !== AxiosError.ERR_NETWORK && error.response?.status !== 403;
        // In all other cases, we send the error to Bugsnag
        if (mustBeSendInBugsnag) {
            sendErrorInBugsnag(error);
        }

        return Promise.reject(error as Error);
    },
);

const isSecureRoute = (url: string): boolean => !UNSECURE_ROUTES.some(route => url.includes(route));

const checkAndRefreshToken = async (): Promise<void> => {
    const authenticatedUser = getUserAuthentication();
    const realmTenantId = store.getState().ui.currentRealm?.tenantId;

    // check if the token is expired and refresh it
    if (
        authenticatedUser?.refreshToken &&
        isPastDate(subMinutes(new Date(authenticatedUser.expiredAt), getAppConfig().REFRESH_TOKEN_EXPIRATION_THRESHOLD)) &&
        realmTenantId
    ) {
        const newUserAuthentication = await authApi.refreshToken(realmTenantId, authenticatedUser.refreshToken);
        // update the user authentication with the new id token
        setUserAuthentication(newUserAuthentication);
    }
};

export const redirect = {
    toLogin: (): void => {
        window.location.href = '/login';
    },
};
