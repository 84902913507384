import { Employee, EmployeeEmailUpdateMutation, EmployeeLoginMethodUpdateMutation, EmployeeStatus } from '@/domain/employee/Employee.model';
import { canImpersonateEmployees, canOnboardOffboardEmployees, hasDeleteEmployeePolicy } from '@/domain/permission/Permission.service';
import { LoginMethod, LoginMethodType } from '@/domain/realm/Realm.model';
import { EmployeeProfileActionType } from '@/stores/reducers/employeeProfileActions';
import { useAppSelector, useCurrentEmployee } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { showSnackbar } from '@/utils/snackbar.util';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, Chip, DialogActions, DialogContent, FormControlLabel, Menu, MenuItem, Stack, Typography } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import { FC, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import * as yup from 'yup';

import { DialogWrapper } from '@/components/dialog-wrapper/DialogWrapper';
import { FieldSelect } from '@/components/form/field-select/FieldSelect';
import { FieldText } from '@/components/form/field-text/FieldText';
import { activateEmployee, deactivateEmployee, deleteEmployee, searchEmployees, updateEmail, updateLoginMethod } from '@/domain/employee/Employee.service';
import { Settings02Icon } from 'hugeicons-react';
import { useNavigate } from 'react-router';
import { impersonateEmployee } from '@/domain/authentication/Authentication.service';
import { AuthenticationStatus } from '@/domain/authentication/Authentication.model';
import { useGetEmployeeEmail, useGetEmployeeLoginMethod } from '@/hooks/employee/Employee.hook';

type Props = {
    employee: Employee;
    onSuccess: () => void;
};

export const EmployeeProfileActionButton: FC<Props> = ({ employee, onSuccess }) => {
    const { t } = useTranslation();
    const spanRef = useRef();
    const [anchorEl, setAnchorEl] = useState<Element>();
    const handleFormActionClose = () => {
        setAnchorEl(undefined);
    };
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const realm = useAppSelector(state => state.ui.currentRealm);
    const loginOptions = realm?.availableLoginMethods ?? [];
    const policies = useAppSelector(state => state.currentEmployee.grantedPolicies);
    const currentEmployee = useCurrentEmployee();
    const { data: employeeLoginMethod } = useGetEmployeeLoginMethod(employee.id);
    const { data: employeeEmail } = useGetEmployeeEmail(employee.id);

    // Maybe we should split this schema into two separate schemas
    // One  when login method is required and one when it is not

    const schema: yup.ObjectSchema<{ email: string; loginMethod?: LoginMethod }> = yup.object().shape({
        email: yup
            .string()
            .email(t('general.forms.invalid_email'))
            .required()
            .test({
                message: t('general.forms.email_already_taken'),
                test: async email => {
                    try {
                        const employees = await searchEmployees({ email });
                        return employees.length === 0 || employees[0].id === employee.id;
                    } catch {
                        return false;
                    }
                },
            }),

        loginMethod: yup
            .object()
            .shape({
                id: yup.number().required(),
                name: yup.string().required(),
                type: yup.string().required().oneOf(Object.values(LoginMethodType)),
            })
            .default(undefined),
    });
    type FormValues = yup.InferType<typeof schema>;

    const [showUpdateEmailDialog, setShowUpdateEmailDialog] = useState(false);
    const [showUpdateLoginMethodDialog, setShowUpdateLoginMethodDialog] = useState(false);
    const [showConfirmEmployeeDeleteDialog, setShowConfirmEmployeeDeleteDialog] = useState(false);
    const { control, handleSubmit, reset } = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: employeeEmail,
            loginMethod: employeeLoginMethod,
        },
    });

    useEffect(() => {
        reset({
            email: employeeEmail,
            loginMethod: employeeLoginMethod,
        });
    }, [employeeEmail, employeeLoginMethod, reset]);

    const grantUserAccess = () => {
        if (!employee) {
            return;
        }
        activateEmployee(employee.id)
            .then(employee => {
                dispatch({ type: EmployeeProfileActionType.EMPLOYEE_PROFILE_LOADED, employee });
                showSnackbar(t('employee.messages.grant_access_success'), 'success');
                onSuccess();
            })
            .catch(error => {
                console.error(error);
                showSnackbar(t('employee.messages.grant_access_failure'), 'error');
            });
    };

    const revokeUserAccess = () => {
        if (!employee) {
            return;
        }
        deactivateEmployee(employee.id)
            .then(employee => {
                dispatch({ type: EmployeeProfileActionType.EMPLOYEE_PROFILE_LOADED, employee });
                showSnackbar(t('employee.messages.revoke_access_success'), 'success');
                onSuccess();
            })
            .catch(error => {
                console.error(error);
                showSnackbar(t('employee.messages.revoke_access_failure'), 'error');
            });
    };

    const impersonate = async () => {
        if (!employee) {
            return;
        }
        try {
            const authStatus = await impersonateEmployee(employee.id);
            if (authStatus === AuthenticationStatus.SUCCESS) {
                location.reload();
            }
        } catch (error) {
            handleError(error);
        }
    };

    const handleEmailUpdate = async ({ email }: { email: string }) => {
        const mutation: EmployeeEmailUpdateMutation = {
            email: email,
        };
        try {
            await updateEmail(employee.id, mutation);
            setShowUpdateEmailDialog(false);
            dispatch({
                type: EmployeeProfileActionType.REFETCH_EMPLOYEE_PROFILE,
                refetchEmployee: true,
            });
            onSuccess();
        } catch (e) {
            handleError(e);
        }
    };

    const handleLoginMethodUpdate = async ({ loginMethod }: { loginMethod: LoginMethod }) => {
        const mutation: EmployeeLoginMethodUpdateMutation = {
            loginMethodId: loginMethod.id,
        };
        try {
            await updateLoginMethod(employee.id, mutation);
            setShowUpdateLoginMethodDialog(false);
            onSuccess();
        } catch (e) {
            handleError(e);
        }
    };

    const onSuccessfulDelete = () => {
        navigate('/people/directory');
    };

    return (
        canOnboardOffboardEmployees(policies) && (
            <Stack>
                <Stack>
                    <Box ref={spanRef} />
                    <Chip
                        // we are using delete icon and not icon because this is the only way to show the icon on the right side
                        deleteIcon={<Settings02Icon />}
                        color={'default'}
                        label={t('my_profile.account_settings')}
                        onClick={() => {
                            setAnchorEl(spanRef.current);
                        }}
                        // We need to implement the delete function to be able to see the settings icon
                        onDelete={() => {
                            setAnchorEl(spanRef.current);
                        }}
                    />
                </Stack>

                <Menu id='manage-leaves-menu' anchorEl={anchorEl} keepMounted open={Boolean(anchorEl)} onClose={handleFormActionClose}>
                    {[
                        employee?.status === EmployeeStatus.INACTIVE && employeeLoginMethod?.type === LoginMethodType.STANDARD && (
                            <MenuItem key='grant-access' onClick={() => grantUserAccess()}>
                                <ListItemText primary={t('my_profile.grant_access')} />
                            </MenuItem>
                        ),
                        employee?.status !== EmployeeStatus.INACTIVE && employeeLoginMethod?.type === LoginMethodType.STANDARD && (
                            <MenuItem key='revoke-access' onClick={() => revokeUserAccess()}>
                                <ListItemText primary={t('my_profile.revoke_access')} />
                            </MenuItem>
                        ),
                        currentEmployee.id !== employee?.id && employee?.status !== EmployeeStatus.INACTIVE && canImpersonateEmployees(policies) && (
                            <MenuItem key='imperosonate' onClick={() => impersonate()}>
                                <ListItemText primary={t('my_profile.impersonate')} />
                            </MenuItem>
                        ),
                        <MenuItem key='update-email' onClick={() => setShowUpdateEmailDialog(true)}>
                            <ListItemText primary={t('my_profile.update_email')} />
                        </MenuItem>,
                        loginOptions.length > 1 && (
                            <MenuItem key='update-login-method' onClick={() => setShowUpdateLoginMethodDialog(true)}>
                                <ListItemText primary={t('my_profile.update_login_method')} />
                            </MenuItem>
                        ),
                        hasDeleteEmployeePolicy(policies) && (
                            <MenuItem key='delete-employee-method' onClick={() => setShowConfirmEmployeeDeleteDialog(true)}>
                                <ListItemText primary={t('my_profile.delete_employee')} />
                            </MenuItem>
                        ),
                    ]}
                </Menu>
                {showUpdateEmailDialog && (
                    <DialogWrapper
                        header={t('my_profile.update_email')}
                        onClose={() => {
                            setShowUpdateEmailDialog(false);
                            reset();
                        }}
                        open={true}
                    >
                        <Stack spacing={2} component={DialogContent}>
                            <FormControlLabel label={t('my_profile.email')} control={<FieldText name='email' control={control} fullWidth />} />
                        </Stack>
                        <DialogActions>
                            <Button fullWidth onClick={handleSubmit(handleEmailUpdate, console.error)}>
                                {t('general.save')}
                            </Button>
                        </DialogActions>
                    </DialogWrapper>
                )}
                {showUpdateLoginMethodDialog && (
                    <DialogWrapper
                        header={t('my_profile.update_login_method')}
                        onClose={() => {
                            setShowUpdateLoginMethodDialog(false);
                            reset();
                        }}
                        open={true}
                    >
                        <Stack spacing={2} component={DialogContent}>
                            <Stack>
                                <Typography variant='body1'>{t('my_profile.login_method')}</Typography>
                                <FieldSelect
                                    name='loginMethod'
                                    control={control}
                                    defaultValue={employeeLoginMethod}
                                    options={loginOptions}
                                    disableClearable
                                    getOptionLabel={login => login?.name}
                                    isOptionEqualToValue={(option, value) => value.id === option.id}
                                    fullWidth
                                />
                            </Stack>
                        </Stack>
                        <DialogActions>
                            <Button
                                fullWidth
                                onClick={handleSubmit(({ loginMethod }) => {
                                    if (!loginMethod) {
                                        throw new Error('Login method is required');
                                    }
                                    handleLoginMethodUpdate({ loginMethod });
                                }, console.error)}
                            >
                                {t('general.save')}
                            </Button>
                        </DialogActions>
                    </DialogWrapper>
                )}
                {showConfirmEmployeeDeleteDialog && employeeEmail && (
                    <ConfirmEmployeeDeletionDialog
                        employeeId={employee.id}
                        employeeEmail={employeeEmail}
                        onSuccess={onSuccessfulDelete}
                        onClose={() => setShowConfirmEmployeeDeleteDialog(false)}
                    />
                )}
            </Stack>
        )
    );
};

type ConfirmEmployeeDeletionProps = {
    employeeId: number;
    employeeEmail: string;
    onSuccess: () => void;
    onClose: () => void;
};

const ConfirmEmployeeDeletionDialog: FC<ConfirmEmployeeDeletionProps> = ({ employeeId, employeeEmail, onSuccess, onClose }) => {
    const { t } = useTranslation();

    const schema: yup.ObjectSchema<{ email: string }> = yup.object().shape({
        email: yup
            .string()
            .email(t('general.forms.invalid_email'))
            .required()
            .test({
                message: t('my_profile.delete_employee_dialog.wrong_email'),
                test: value => {
                    return value === employeeEmail;
                },
            }),
    });

    type FormValues = yup.InferType<typeof schema>;

    const { control, handleSubmit } = useForm<FormValues>({
        resolver: yupResolver(schema),
        defaultValues: {
            email: '',
        },
    });

    const handleOnDelete = async (formValues: FormValues) => {
        try {
            await deleteEmployee(employeeId, formValues.email);
            onSuccess();
        } catch {
            showSnackbar(t('my_profile.delete_employee_dialog.error'), 'error');
        }
    };

    return (
        <DialogWrapper onClose={onClose} open={true} header={t('my_profile.delete_employee_dialog.title')}>
            <Stack component={DialogContent} gap={2}>
                <Typography>{t('my_profile.delete_employee_dialog.confirmation_text', { email: employeeEmail })}</Typography>
                <FormControlLabel
                    label={t('my_profile.delete_employee_dialog.email_confirmation')}
                    control={<FieldText name='email' control={control} fullWidth />}
                />
            </Stack>
            <DialogActions>
                <Button fullWidth onClick={handleSubmit(handleOnDelete, console.error)} color='error'>
                    {t('my_profile.delete_employee_dialog.confirm_button')}
                </Button>
            </DialogActions>
        </DialogWrapper>
    );
};
