import { EmptyState } from '@/components/empty-state/EmptyState';
import { ErrorEmptyStateIcon } from '@/components/empty-state/icons/ErrorEmptyStateIcon';
import { LogoRoger } from '@/components/logo-roger/LogoRoger';
import { StateHandler } from '@/components/state-handler/StateHandler';
import { getCurrentEmployee, searchCurrentEmployeeLoginMethod } from '@/domain/employee/Employee.service';
import { setUserLanguage } from '@/utils/language.util';
import { LoginMethodType, Realm } from '@/domain/realm/Realm.model';
import { employeeAuthenticated } from '@/stores/reducers/currentEmployeeSlice';
import { useAppDispatch, useAppSelector } from '@/stores/store';
import { handleError } from '@/utils/api.util';
import { Alert, Link, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { FC, MouseEvent, useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { Link as RouterLink, useLocation } from 'react-router';
import { FindLoginMethod } from '@/page/auth/login/FindLoginMethod';
import { SSOLoginForm } from '@/page/auth/login/SSOLoginForm';
import { StandardLoginForm } from '@/page/auth/login/StandardLoginForm';
import { EmployeeSearchLoginMethodRequest } from '@/domain/employee/Employee.model';
import { EnterRealmName } from '@/page/auth/login/EnterRealmName';
import { AuthenticationStatus } from '@/domain/authentication/Authentication.model';
import { oauth2SignIn, signIn } from '@/domain/authentication/Authentication.service';

type errorType = 'REALM_NOT_FOUND' | 'EMAIL_NOT_FOUND' | 'ERROR' | 'INCORRECT_USERNAME_PASSWORD' | undefined;

export const LoginPage: FC = () => {
    const dispatch = useAppDispatch();
    const realm = useAppSelector(state => state.ui.currentRealm);
    const [errorType, setErrorType] = useState<errorType>(!realm ? 'REALM_NOT_FOUND' : undefined);
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const { t } = useTranslation();
    const [loginMethodType, setLoginMethodType] = useState<LoginMethodType>();
    const [email, setEmail] = useState<string>('');
    const isMobile = useMediaQuery(useTheme().breakpoints.down('sm'));

    const onLoginClicked = async (email: string, password: string) => {
        setLoading(true);

        if (!realm) {
            setErrorType('REALM_NOT_FOUND');
            setLoading(false);
            return;
        }

        try {
            const response = await signIn(email?.toLocaleLowerCase(), password, realm.tenantId);

            if (response === AuthenticationStatus.SUCCESS) {
                // at this time the user is logged in! \o/, if no MFA required
                const data = await getCurrentEmployee();

                if (data?.language) {
                    setUserLanguage(data?.language).catch(handleError);
                }
                dispatch(employeeAuthenticated(data));
            } else if (response === AuthenticationStatus.INVALID_CREDENTIALS) {
                setErrorType('INCORRECT_USERNAME_PASSWORD');
            } else {
                console.error('Invalid auth status', response);
                setErrorType('ERROR');
            }
        } catch (error) {
            console.error(error);
            setErrorType('ERROR');
        }

        setLoading(false);
    };

    const federatedSignIn = async (realm: Realm, provider: string) => {
        const authorizeUrl = await oauth2SignIn(realm.tenantId, provider);
        window.location.replace(authorizeUrl);
    };

    const onFindLoginMethodClick = async (email: string) => {
        if (!realm) {
            return;
        }
        setLoading(true);
        const employeeSearchLoginMethodRequest: EmployeeSearchLoginMethodRequest = {
            email: email,
            tenantId: realm.tenantId,
        };
        try {
            const loginMethod = await searchCurrentEmployeeLoginMethod(employeeSearchLoginMethodRequest);
            setLoginMethodType(loginMethod.loginMethodType);
            setEmail(email);
        } catch (error) {
            console.error(error);
            setErrorType('EMAIL_NOT_FOUND');
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        const isAvailableLoginMethodUnique = realm?.availableLoginMethods?.length === 1;
        if (isAvailableLoginMethodUnique) {
            setLoginMethodType(realm?.availableLoginMethods?.[0].type);
        }
    }, [realm?.availableLoginMethods]);

    const onAzureLoginClicked = async (realm: Realm, event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setLoading(true);
        await federatedSignIn(realm, 'MicrosoftSSO');
    };

    const onOktaLoginClicked = async (realm: Realm, event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setLoading(true);
        await federatedSignIn(realm, 'OktaWebFlow');
    };

    const onOpenIdLoginClicked = async (realm: Realm, event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setLoading(true);
        await federatedSignIn(realm, 'SSO');
    };

    const onGoogleLoginClicked = async (realm: Realm, event: MouseEvent<HTMLElement>) => {
        event.preventDefault();
        setLoading(true);
        await federatedSignIn(realm, 'Google');
    };

    const getErrorComponent = () => {
        switch (errorType) {
            case 'ERROR': {
                return (
                    <EmptyState
                        flex='1'
                        justifyContent='center'
                        alignItems='center'
                        textAlign='center'
                        icon={<ErrorEmptyStateIcon />}
                        title={t('login_form.error_occurred')}
                        subTitle={t('login_form.please_try_again_later')}
                    />
                );
            }

            case 'REALM_NOT_FOUND': {
                const realmName = window.location.host.split('.')[0];
                const isRogerApp = realmName === 'app';
                return (
                    <Stack gap={2}>
                        <EnterRealmName />
                        {!isRogerApp && (
                            <Alert severity='error' elevation={0}>
                                <Typography variant='body2'>
                                    <Trans i18nKey='login_form.realm_not_exist' values={{ realm: realmName }} />
                                </Typography>
                            </Alert>
                        )}
                    </Stack>
                );
            }

            case 'EMAIL_NOT_FOUND': {
                return (
                    <Stack gap={2}>
                        {loginStack()}
                        <Alert severity='error' elevation={0}>
                            <Typography variant='body2'>{t('login_form.email_not_found')}</Typography>
                        </Alert>
                    </Stack>
                );
            }

            case 'INCORRECT_USERNAME_PASSWORD': {
                return (
                    <Stack gap={2} justifyContent={'flex-start'}>
                        {loginStack()}
                        <Alert severity='error' elevation={0}>
                            <Typography variant='body2'>{t('login_form.incorrect_username_password')}</Typography>
                        </Alert>
                    </Stack>
                );
            }

            default: {
                return <></>;
            }
        }
    };

    const renderLoginForm = (realm: Realm, loginMethodType: LoginMethodType | undefined, location: string) => {
        // Short cut to login with SSO
        switch (location) {
            case '/google-login':
                return <SSOLoginForm buttonName={t('login_form.login_with_google')} handleClick={event => onGoogleLoginClicked(realm, event)} />;
            case '/microsoft-login':
                return <SSOLoginForm buttonName={t('login_form.login_with_microsoft')} handleClick={event => onAzureLoginClicked(realm, event)} />;
            case '/okta-login':
                return <SSOLoginForm buttonName={t('login_form.login_with_okta')} handleClick={event => onOktaLoginClicked(realm, event)} />;
            case '/standard-login':
                return <StandardLoginForm onLoginClicked={onLoginClicked} loading={loading} />;
        }

        //In case there is multiple login methods or it is not defined yet
        if (!loginMethodType) {
            return <FindLoginMethod onFindLoginMethodClick={onFindLoginMethodClick} loading={loading} />;
        }

        switch (loginMethodType) {
            case LoginMethodType.MICROSOFT:
                return <SSOLoginForm buttonName={t('login_form.login_with_microsoft')} handleClick={event => onAzureLoginClicked(realm, event)} />;
            case LoginMethodType.GOOGLE:
                return <SSOLoginForm buttonName={t('login_form.login_with_google')} handleClick={event => onGoogleLoginClicked(realm, event)} />;
            case LoginMethodType.OPENID:
                return <SSOLoginForm buttonName={t('login_form.login_with_openid')} handleClick={event => onOpenIdLoginClicked(realm, event)} />;
            case LoginMethodType.OKTA:
                return <SSOLoginForm buttonName={t('login_form.login_with_okta')} handleClick={event => onOktaLoginClicked(realm, event)} />;
            case LoginMethodType.STANDARD:
                return <StandardLoginForm onLoginClicked={onLoginClicked} loading={loading} email={email} />;
        }
    };
    const loginStack = () => {
        return (
            <Stack gap={2}>
                <Stack>
                    <Typography variant='body2bold'>
                        {t('login_form.log_in_to')} {realm?.name ?? t('login_form.default_realm_name')}
                    </Typography>
                    <Typography variant='body2'>{window.location.host}</Typography>
                </Stack>
                {realm && renderLoginForm(realm, loginMethodType, location.pathname)}
                {(loginMethodType === 'STANDARD' || location.pathname === '/standard-login') && (
                    <Typography variant='body2'>
                        <Link to='/forgot-password' component={RouterLink} color='primary'>
                            {t('login_form.forgot_your_password')}
                        </Link>
                    </Typography>
                )}
            </Stack>
        );
    };

    return (
        <Stack
            gap={3}
            component={Paper}
            width={isMobile ? '80vw' : 440}
            p={isMobile ? 3 : 5}
            left='50%'
            top='50%'
            position='absolute'
            sx={{
                transform: 'translate(-50%, -50%)',
            }}
        >
            <Stack direction='row' alignContent='center'>
                <LogoRoger variant='light' />
            </Stack>
            <StateHandler isLoading={loading} isError={!!errorType} errorComponent={getErrorComponent()} error={getError(errorType)}>
                {realm && loginStack()}
            </StateHandler>
        </Stack>
    );
};

const getError = (errorType: errorType): Error | undefined => {
    switch (errorType) {
        case 'ERROR': {
            return new Error('Error to load login form');
        }
        // We don't want to send errors to bugsnag for these cases
        case 'EMAIL_NOT_FOUND':
        case 'INCORRECT_USERNAME_PASSWORD':
        case 'REALM_NOT_FOUND':
        default:
            return undefined;
    }
};
