import { Page } from '@/page/Pages';
import { canSeeOtherEmployeeSkills } from '@/domain/permission/Permission.service';
import { RealmFeature } from '@/domain/realm/Realm.model';
import { EmployeePolicy } from '@/domain/employee/Employee.model';

export const getEmployeeSkillPages = (employeeId: number, realmFeatures: RealmFeature[], policies: EmployeePolicy[]): Page[] => {
    const allPages: Page[] = [
        {
            labelKey: 'sidebar.employee_skill',
            path: '/employee-skill/skills-matrix',
            condition: (_, policies: EmployeePolicy[]) => canSeeOtherEmployeeSkills(policies, employeeId),
        },
    ];
    return allPages.filter(page => !page?.condition || page?.condition?.(realmFeatures, policies));
};
