import { EmployeeAvatarDTO, EmployeeDTO, mapEmployeeDTO } from '@/api/employee/Employee.api';
import {
    EmployeeReviewFeedbackObjectiveSummary,
    EmployeeReviewFeedbackQuestionSummary,
    EmployeeReviewFeedbackSectionSummary,
    EmployeeReviewFeedbackSkillsSummary,
    EmployeeReviewFeedbackSkillSummary,
    EmployeeReviewFeedbackSummary,
    EmployeeReviewManagerSummary,
    EmployeeReviewSelfSummary,
    EmployeeReviewSummaryItem,
} from '@/domain/employee-review-feedback-summary/EmployeeReviewFeedbackSummary.model';
import { ContributorType } from '@/domain/review/Review.model';
import { mapToReviewRatingScale, ReviewRatingScaleDTO } from '@/api/review-rating-scale/ReviewRatingScale.api';
import { EmployeeReviewFeedbackRequest, ReviewFeedbackDTO } from '@/api/employee-review-feedback/EmployeeReviewFeedback.api';
import { SkillDTO } from '@/api/skill/Skill.api';
import { mapObjectiveDTO, ObjectiveDTO } from '@/api/objective/Objective.api';
import { AxiosResponse } from 'axios';
import { API_BASE_URL, client } from '@/api/common';
import { EmployeeReviewFeedbackMutation } from '@/domain/employee-review-feedback/EmployeeReviewFeedback.model';
import { ReviewItemDTO } from '@/api/review/Review.api';
import { ReviewTemplateItemType } from '@/domain/review-template/ReviewTemplate.model';
import { convertUTCIsoStringToDate } from '@/utils/datetime.util';

const mapFeedbackObjectiveSummaryDTO = (feedbackObjectiveSummary: FeedbackObjectiveSummaryDTO): EmployeeReviewFeedbackObjectiveSummary => {
    return {
        ...feedbackObjectiveSummary,
        reviewObjectives: mapObjectiveDTO(feedbackObjectiveSummary.reviewObjectives),
    };
};

const mapSummaryItemDTO = (summaryItem: EmployeeReviewSummaryItemDTO): EmployeeReviewSummaryItem => {
    return {
        ...summaryItem,
        feedbackQuestionSummary: summaryItem.question,
        feedbackSkillQuestionSummary: summaryItem.skillQuestion,
        feedbackSectionSummary: summaryItem.section ? summaryItem.section : undefined,
        feedbackObjectivesSummary: summaryItem.reviewObjectives
            ? summaryItem.reviewObjectives.map(objective => mapFeedbackObjectiveSummaryDTO(objective))
            : undefined,
        rating: summaryItem.rating ? mapToReviewRatingScale(summaryItem.rating) : undefined,
        feedbackSkillsSummary: summaryItem.skills,
        reviewItemId: summaryItem.reviewItemId,
        type: summaryItem.itemType,
    };
};

export type EmployeeReviewFeedbackSummaryDTO = Omit<EmployeeReviewFeedbackSummary, 'reviewItem' | 'objective'> & {
    reviewItem: ReviewItemDTO;
    objective: ObjectiveDTO | undefined;
};

export type EmployeeReviewSelfSummaryDTO = Omit<EmployeeReviewSelfSummary, 'items' | 'employee' | 'managers' | 'approvedAt' | 'closedAt' | 'closedBy'> & {
    items: EmployeeReviewSummaryItemDTO[];
    employee: EmployeeDTO;
    managers: EmployeeAvatarDTO[];
    approvedAt: string;
    closedAt: string;
    closedBy: EmployeeAvatarDTO | undefined;
};

const mapManagerSummaryDTO = (managerSummary: EmployeeReviewManagerSummaryDTO): EmployeeReviewManagerSummary => {
    return {
        ...managerSummary,
        employee: mapEmployeeDTO(managerSummary.employee),
        managers: managerSummary.managers,
        upwardReviewers: managerSummary.upwardReviewers,
        peerReviewers: managerSummary.peerReviewers,
        items: managerSummary.items.map(item => mapSummaryItemDTO(item)),
        approvedAt: convertUTCIsoStringToDate(managerSummary.approvedAt),
        closedAt: convertUTCIsoStringToDate(managerSummary.closedAt),
        closedBy: managerSummary.closedBy ? managerSummary.closedBy : undefined,
    };
};

const mapFeedbackSummaryDTO = (feedbackSummary: EmployeeReviewFeedbackSummaryDTO): EmployeeReviewFeedbackSummary => {
    return {
        ...feedbackSummary,
        reviewItem: {
            ...feedbackSummary.reviewItem,
            rating: feedbackSummary.reviewItem.rating ? mapToReviewRatingScale(feedbackSummary.reviewItem.rating) : undefined,
        },
        objective: feedbackSummary.objective ? mapObjectiveDTO(feedbackSummary.objective) : undefined,
    };
};

const mapSelfSummaryDTO = (selfSummary: EmployeeReviewSelfSummaryDTO): EmployeeReviewSelfSummary => {
    return {
        ...selfSummary,
        employee: mapEmployeeDTO(selfSummary.employee),
        managers: selfSummary.managers,
        items: selfSummary.items.map(item => mapSummaryItemDTO(item)),
        approvedAt: convertUTCIsoStringToDate(selfSummary.approvedAt),
        closedAt: convertUTCIsoStringToDate(selfSummary.closedAt),
        closedBy: selfSummary.closedBy ? selfSummary.closedBy : undefined,
    };
};

const getFeedbacksManagerSummary = async (employeeReviewId: number): Promise<EmployeeReviewManagerSummary> => {
    const { data } = await client.get<EmployeeReviewManagerSummaryDTO>(API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/manager/summary`);
    return mapManagerSummaryDTO(data);
};

const createFeedbacksManagerSummary = async (
    employeeReviewId: number,
    employeeReviewFeedbackSummaryMutations: EmployeeReviewFeedbackMutation[],
): Promise<EmployeeReviewFeedbackSummary[]> => {
    const { data } = await client.post<EmployeeReviewFeedbackSummaryDTO[], AxiosResponse<EmployeeReviewFeedbackSummaryDTO[], EmployeeReviewFeedbackRequest[]>>(
        API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/manager/summary`,
        employeeReviewFeedbackSummaryMutations,
    );
    return data.map(mapFeedbackSummaryDTO);
};

const createFeedbacksManagerSummaryItem = async (
    employeeReviewId: number,
    feedbackSummaryMutation: EmployeeReviewFeedbackMutation,
): Promise<EmployeeReviewFeedbackSummary> => {
    const { data } = await client.post<EmployeeReviewFeedbackSummaryDTO, AxiosResponse<EmployeeReviewFeedbackSummaryDTO, EmployeeReviewFeedbackRequest>>(
        API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/manager/summary/item`,
        feedbackSummaryMutation,
    );
    return mapFeedbackSummaryDTO(data);
};

const getFeedbacksSummary = async (employeeReviewId: number): Promise<EmployeeReviewManagerSummary> => {
    const { data } = await client.get<EmployeeReviewManagerSummaryDTO>(API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/summary`);
    return mapManagerSummaryDTO(data);
};

const getFeedbacksSelfSummary = async (employeeReviewId: number): Promise<EmployeeReviewSelfSummary> => {
    const { data } = await client.get<EmployeeReviewSelfSummaryDTO>(API_BASE_URL + `/employee-review-feedbacks/${employeeReviewId}/self/summary`);
    return mapSelfSummaryDTO(data);
};

export type EmployeeReviewManagerSummaryDTO = Omit<
    EmployeeReviewManagerSummary,
    'items' | 'employee' | 'managers' | 'upwardReviewers' | 'peerReviewers' | 'approvedAt' | 'closedAt' | 'closedBy'
> & {
    items: EmployeeReviewSummaryItemDTO[];
    employee: EmployeeDTO;
    managers: EmployeeAvatarDTO[];
    upwardReviewers: EmployeeAvatarDTO[];
    peerReviewers: EmployeeAvatarDTO[];
    approvedAt: string;
    closedAt: string;
    closedBy: EmployeeAvatarDTO | undefined;
};

export type EmployeeReviewFeedbackQuestionSummaryDTO = Omit<EmployeeReviewFeedbackQuestionSummary, 'self' | 'managers' | 'peers' | 'upwards'> & {
    self: ReviewFeedbackDTO | undefined;
    managers: ReviewFeedbackDTO[] | undefined;
    peers: ReviewFeedbackDTO[] | undefined;
    upwards: ReviewFeedbackDTO[] | undefined;
};

type EmployeeReviewFeedbackSkillSummaryDTO = Omit<EmployeeReviewFeedbackSkillSummary, 'self' | 'managers' | 'peers' | 'upwards' | 'skill'> & {
    self: ReviewFeedbackDTO | undefined;
    managers: ReviewFeedbackDTO[] | undefined;
    peers: ReviewFeedbackDTO[] | undefined;
    upwards: ReviewFeedbackDTO[] | undefined;
    skill: SkillDTO;
};

type FeedbackSectionSummaryDTO = EmployeeReviewFeedbackSectionSummary;

type FeedbackObjectiveSummaryDTO = Omit<EmployeeReviewFeedbackObjectiveSummary, 'reviewObjectives'> & {
    reviewObjectives: ObjectiveDTO;
};

type FeedbackSkillsSummaryDTO = Omit<EmployeeReviewFeedbackSkillsSummary, 'skills'> & {
    skills: EmployeeReviewFeedbackSkillSummaryDTO[];
};

export type EmployeeReviewSummaryItemDTO = Omit<
    EmployeeReviewSummaryItem,
    | 'feedbackQuestionSummary'
    | 'feedbackSkillQuestionSummary'
    | 'feedbackSectionSummary'
    | 'feedbackObjectivesSummary'
    | 'feedbackSkillsSummary'
    | 'rating'
    | 'contributorType'
    | 'type'
> & {
    question: EmployeeReviewFeedbackQuestionSummaryDTO | undefined;
    skillQuestion: EmployeeReviewFeedbackSkillSummaryDTO | undefined;
    section: FeedbackSectionSummaryDTO | undefined;
    reviewObjectives: FeedbackObjectiveSummaryDTO[] | undefined;
    skills: FeedbackSkillsSummaryDTO | undefined;
    rating: ReviewRatingScaleDTO | undefined;
    contributorTypes: ContributorType;
    itemType: ReviewTemplateItemType;
};

export const employeeReviewFeedbackSummaryApi = {
    getFeedbacksManagerSummary,
    createFeedbacksManagerSummary,
    createFeedbacksManagerSummaryItem,
    getFeedbacksSummary,
    getFeedbacksSelfSummary,
};
