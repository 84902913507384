import { ClockInOut } from '@/components/clock-in-out/ClockInOut';
import { EmployeeSearch } from '@/domain-ui/employee/employee-search/EmployeeSearch';
import { UserAccess } from '@/page/layout/UserAccess';
import { AppBar, AppBarProps, capitalize, Chip, Stack, Toolbar, Tooltip, Typography } from '@mui/material';
import { FC } from 'react';

import { Environment, getAppEnv } from '@/config/config';
import { canClockInOut, canViewOtherEmployeeBasicInfo } from '@/domain/permission/Permission.service';
import { RealmFeaturesType } from '@/domain/realm/Realm.model';
import { hasRealmFeatureEnabled } from '@/domain/realm/Realm.service';
import { ErrorBoundary } from '@/error/ErrorBoundary';
import { HeaderBackButton } from '@/page/layout/HeaderBackButton';
import { isBetaPage } from '@/page/Pages';
import { useGetTimesheetSetting } from '@/page/setting/time-management/TimesheetSettings.hook';
import { useAppSelector, useCurrentEmployee, useCurrentPolicies, useCurrentRealm } from '@/stores/store';
import { desktopVisible } from '@/theme/responsive';
import { isMobileDeviceFromUserAgent } from '@/utils/device.util';
import { TestTubeIcon } from 'hugeicons-react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router';

export const Header: FC<AppBarProps> = ({ ...rest }) => (
    <AppBar
        // header is white
        color='inherit'
        elevation={0}
        {...rest}
    >
        <Toolbar variant={'dense'}>
            <ErrorBoundary>
                <ContentHeader />
            </ErrorBoundary>
        </Toolbar>
    </AppBar>
);

const ContentHeader: FC = () => {
    const { t } = useTranslation();
    const grantedPolicies = useCurrentPolicies();
    const currentEmployee = useCurrentEmployee();
    const params = useParams();
    const profileEmployeeId = Number(params.employeeId) || undefined;
    const currentTimesheetSettingId = currentEmployee.currentWorkingPattern?.timesheetSetting?.id;
    const realm = useCurrentRealm();
    const breadCrumb = useAppSelector(state => state.ui.breadCrumb);

    const location = useLocation();

    const { data: timesheetSetting } = useGetTimesheetSetting(currentTimesheetSettingId);

    const currentEmployeeId = currentEmployee.id;
    const canViewAtLeastOneEmployee = canViewOtherEmployeeBasicInfo(grantedPolicies, currentEmployeeId);
    const getHeaderTitle = (): string => {
        const page = breadCrumb?.[breadCrumb.length - 1];
        if (page?.label) {
            return page?.label;
        }
        return page?.nameKey ? t(page?.nameKey) : '';
    };

    const clockInOutEnabled = () => {
        return (
            canClockInOut(grantedPolicies, currentEmployeeId) &&
            hasRealmFeatureEnabled(realm.realmFeatures, RealmFeaturesType.CLOCK_IN_OUT) &&
            canClockInOutMobile()
        );
    };

    function canClockInOutMobile(): boolean {
        if (!timesheetSetting) {
            return false;
        }
        return isMobileDeviceFromUserAgent() ? timesheetSetting?.mobileClockInOut : true;
    }

    const envWithoutHeaderDisplay = [Environment.PROD, Environment.DEV];
    const appEnv = getAppEnv();
    const envDisplay = appEnv && !envWithoutHeaderDisplay.includes(appEnv) ? `${capitalize(appEnv)}` : undefined;

    const displayBetaFlag = isBetaPage(location);

    return (
        <Stack direction='row' justifyContent='space-between' alignItems='center' flex='1'>
            <Stack direction='row' alignItems='center' gap={1}>
                <HeaderBackButton />

                <Typography variant='h1'>{getHeaderTitle()}</Typography>

                {displayBetaFlag && (
                    <Tooltip title={t('general.beta_description')}>
                        <Chip
                            icon={<TestTubeIcon width='20' height='20' />}
                            label={
                                <Typography variant='body2bold' color='primary'>
                                    {t('general.beta')}
                                </Typography>
                            }
                            color='primary'
                        />
                    </Tooltip>
                )}
            </Stack>

            {!!envDisplay && (
                <Chip
                    label={
                        <Typography variant='h1' color='primary'>
                            {envDisplay}
                        </Typography>
                    }
                    color='primary'
                    sx={{
                        display: 'flex',
                        flex: 1,
                        mx: 2,
                    }}
                />
            )}

            <Stack direction='row' alignItems='center' spacing={2}>
                {clockInOutEnabled() && <ClockInOut currentEmployee={currentEmployee} />}
                <Stack direction='row' alignItems='center' spacing={2} sx={desktopVisible}>
                    {canViewAtLeastOneEmployee && <EmployeeSearch employeeId={profileEmployeeId} />}
                    <UserAccess />
                </Stack>
            </Stack>
        </Stack>
    );
};
